import LoginTemplate from 'src/modules/account/templates/LoginTemplate';
import backgroundImg from '../../assets/USADF_watermark.svg';

const LoginPage = () => {
  return (
    <div>
      <div className='h-full absolute hidden lg:flex '>
        <img src={backgroundImg} alt='USADF' />
      </div>
      <LoginTemplate />
    </div>
  );
};

export default LoginPage;
