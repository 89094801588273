import {useRegister} from 'src/lib/context/register-context';
import {useAccount} from 'src/lib/context/account-context';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'src/modules/common/components/Button';
import Lottie from 'react-lottie-player';
import lessonCompleteAnimation from '../../../assets/lotties/usadf-lesson-complete.json';

const RegistrationComplete = () => {
  const {refetchuser} = useAccount();
  const {complete} = useRegister();
  const navigate = useNavigate();

  useEffect(() => {
    refetchuser();
  }, [complete, refetchuser]);

  return (
    <>
      {complete && (
        <div style={{width: '360px'}} className='flex flex-col items-center'>
          <span className='text-3xl font-semibold mb-6 font-effra-bold text-[#22436D] uppercase'>
            Complete
          </span>{' '}
          <span className='text-center text-base-regular text-gray-700 mb-8'>
            Click below to start your journey
          </span>
          <Lottie
            className='text-primary block w-auto mb-16'
            loop
            animationData={lessonCompleteAnimation}
            play
            style={{width: 178, height: 179}}
          />
          <Button
            className='w-full bg-primary-active'
            onClick={() => {
              navigate('/dashboard');
              window.location.reload();
            }}
          >
            View Your Dashboard
          </Button>
        </div>
      )}
    </>
  );
};

export default RegistrationComplete;
