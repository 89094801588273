import ConnectForm from 'src/modules/common/components/ConnectFrom';
import {RegistrationFrom, useRegister} from 'src/lib/context/register-context';
import {Label} from 'src/modules/common/components/Label';
import Input from 'src/modules/common/components/Input';
import RadioGroup from 'src/modules/common/components/Radio';
import {Button} from 'src/modules/common/components/Button';

const AdditionalBusinessDetails = () => {
  const {
    confirmationError,
    handleSubmit,
    saveRegister,
    getValues,
    editAdditionalBusinessDetailsForm: {state: isEdit}
  } = useRegister();

  const business_details = getValues('business_details.has_entity_name')?.toString();

  return (
    <div>
      {isEdit && (
        <>
          <ConnectForm<RegistrationFrom>>
            {({register, formState: {errors, touchedFields}}) => (
              <div className='grid grid-cols-1'>
                {business_details === 'true' && (
                  <Input
                    label={'Business / Entity Name'}
                    {...register('additional_business_details.name', {
                      required: 'Name is required'
                    })}
                    errors={errors}
                    touched={touchedFields}
                  />
                )}

                <div className='flex flex-col gap-6'>
                  <Label label={'Do you have financial goals for your business?'}>
                    <RadioGroup
                      {...register('additional_business_details.has_financial_goals', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                  <Label label={'Do you have financing for your business?'}>
                    <RadioGroup
                      {...register('additional_business_details.has_secured_financing', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                  <Label label={'Does your company make a profit?'}>
                    <RadioGroup
                      {...register('additional_business_details.is_profitable', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                  <Label
                    label={'Do you have goals for your business and know how to achieve them?'}
                  >
                    <RadioGroup
                      {...register('additional_business_details.has_scailing_plan', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                </div>

                <Button className='mt-6 bg-primary-active' onClick={handleSubmit(saveRegister)}>
                  Submit
                </Button>
                {confirmationError && (
                  <div className='pt-1 pl-2 text-rose-500 text-small-regular'>
                    <span>{confirmationError}</span>
                  </div>
                )}
              </div>
            )}
          </ConnectForm>
        </>
      )}
    </div>
  );
};

export default AdditionalBusinessDetails;
