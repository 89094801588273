import {BrowserRouter} from 'react-router-dom';
import './styles/globals.css';
import {MobileMenuProvider} from './lib/context/mobile-menu-context';
import MainRouter from './router/MainRouter';
import {AccountProvider} from './lib/context/account-context';
import {QueryProvider} from './lib/context/query-context';

function App() {
  return (
    <>
      <QueryProvider>
        <BrowserRouter>
          <AccountProvider>
            <MobileMenuProvider>
              <MainRouter />
            </MobileMenuProvider>
          </AccountProvider>
        </BrowserRouter>
      </QueryProvider>
    </>
  );
}

export default App;
