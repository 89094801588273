import {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import {useGetCountry} from 'src/api';
import NativeSelect, {NativeSelectProps} from 'src/modules/common/components/NativeSelect';

const CountrySelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  ({placeholder = 'Country', ...props}, ref) => {
    const innerRef = useRef<HTMLSelectElement>(null);

    useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(
      ref,
      () => innerRef.current
    );

    const {data: countries} = useGetCountry();

    const countryOptions = useMemo(() => {
      return countries?.map(country => ({
        value: country.iso_code,
        label: country.name
      }));
    }, [countries]);

    return (
      <NativeSelect ref={innerRef} placeholder={placeholder} {...props}>
        {countryOptions?.map(({value, label}, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </NativeSelect>
    );
  }
);

CountrySelect.displayName = 'CountrySelect';

export default CountrySelect;
