import React from 'react';
import {IconProps} from 'src/types/icon';

const Plus: React.FC<IconProps> = ({size = '16', color = 'currentColor', ...attributes}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...attributes}
    >
      <path
        d='M8 3.33331V12.6666'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33301 8H12.6663'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Plus;
