import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

interface QueryProviderProps {
  children?: React.ReactNode;
}

export const QueryProvider = ({children}: QueryProviderProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 90000,
        retry: 1
      }
    }
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
