import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {UserLogin} from 'src/api/models';
import {useAccount} from 'src/lib/context/account-context';
import logo from '../../../assets/Learning_Hub_Logo.png';
import {usePostLogin} from 'src/api';
import Spinner from 'src/modules/common/icons/spinner';
import {useNavigate} from 'react-router-dom';
import {Button} from 'src/modules/common/components/Button';
import Input from 'src/modules/common/components/Input';

const Login = () => {
  const navigate = useNavigate();
  const date = new Date();

  const {refetchuser} = useAccount();
  const [authError, setAuthError] = useState<string | undefined>(undefined);

  const {mutate: login, isLoading} = usePostLogin();
  const handleError = (_e: Error) => {
    setAuthError('Invalid email or password');
  };

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm<UserLogin>();

  const onSubmit = handleSubmit(async credentials => {
    login(
      {data: credentials},
      {
        onError: e => {
          handleError(e);
        },
        onSuccess: res => {
          localStorage.setItem('token', res.access_token);
          refetchuser();
          navigate('/dashboard');
          window.location.reload();
        }
      }
    );
  });

  return (
    <div className='max-w-xl w-full flex flex-col items-center gap-10 px-5 lg:px-0'>
      <div className='h-44 w-auto lg:w-96'>
        <img
          style={{height: 'auto', width: 'auto'}}
          src={logo}
          alt='Logo'
          className='block w-auto mb-16 h-9'
        />
      </div>
      <div className='flex flex-col'>
        <span className='text-[32px] lg:text-[42px] font-bold mb-6 normal-case lg:uppercase text-center w-full text-[#22436D] font-effra-bold'>
          Log in to your account
        </span>
        <span className='text-center text-[#475467] mb-8 font-effra-normal'>
          Welcome back! Please enter your details.
        </span>
      </div>
      <form className='w-full flex flex-col' onSubmit={onSubmit}>
        <div className='flex flex-col w-full gap-y-2'>
          <Input
            placeholder='Enter your USADF Unique Identifier'
            label='Unique Identifier'
            {...register('usadf_id', {required: 'USADF Unique Identifier is required'})}
            autoComplete='current-password'
            errors={errors}
            className='text-sm font-medium text-[#475467]'
          />
        </div>
        {authError && (
          <div>
            <span className='text-rose-500 w-full text-small-regular'>
              These credentials do not match our records
            </span>
          </div>
        )}
        <Button className='mt-6 bg-primary-active'>
          <span className='font-semibold'>{isLoading ? <Spinner /> : 'Sign in'}</span>
        </Button>
      </form>
      <div className='flex flex-col items-center'>
        <span className='text-gray-700 text-small-regular mt-6'>
          &copy; {date.getFullYear()} USADF. All rights reserved.
        </span>
        <span className='text-center text-gray-700 text-small-regular mt-6'>
          Don’t have an account yet?{' '}
          <button onClick={() => navigate('/register')} className='font-bold text-primary-active'>
            Register Here
          </button>
          .
        </span>
      </div>
    </div>
  );
};

export default Login;
