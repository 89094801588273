import {ErrorErrors} from 'src/api/models';
import {ErrorType} from './fetcher';

interface Error {
  message?: string;
  code?: number;
  errors?: ErrorErrors;
  status?: string;
  response?: unknown;
}

export const getErrorMessage = (error: ErrorType<Error>) => {
  let msg = error?.response?.data?.message;
  if (!msg) {
    msg = 'Something went wrong, Please try again.';
  }

  return msg;
};
