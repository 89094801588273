import RegisterForm from './RegisterForm';

const ResisterTemplate = () => {
  return (
    <div className=' h-screen w-full grid place-items-center bg-[#FDF9F2]'>
      <RegisterForm />
    </div>
  );
};

export default ResisterTemplate;
