import {emailRegex} from 'src/lib/util/regex';
import ConnectForm from 'src/modules/common/components/ConnectFrom';
import Input from 'src/modules/common/components/Input';
import {RegistrationFrom, useRegister} from 'src/lib/context/register-context';
import {Button} from 'src/modules/common/components/Button';

const UserDetails = () => {
  const {
    verificationError,
    verifyUser,
    handleSubmit,
    editUserDetailsForm: {state: isEdit}
  } = useRegister();

  return (
    <div>
      {isEdit && (
        <>
          <div className='w-full flex flex-col items-center'>
            <span className='text-[42px] font-semibold  font-effra-bold text-[#22436D] uppercase'>
              Register
            </span>{' '}
            <span className='text-center text-base-regular text-gray-700 mb-8'>
              To start your journey to success
            </span>
          </div>
          <ConnectForm<RegistrationFrom>>
            {({register, formState: {errors, touchedFields}}) => (
              <div className='grid grid-cols-1 space-y-5'>
                <Input
                  label='First name'
                  {...register('personal_info.name', {
                    required: 'First name is required'
                  })}
                  autoComplete='given-name'
                  errors={errors}
                  touched={touchedFields}
                  className='text-sm font-medium text-[#475467]'
                />
                <Input
                  label='Last name'
                  {...register('personal_info.surname', {
                    required: 'Last name is required'
                  })}
                  autoComplete='family-name'
                  errors={errors}
                  touched={touchedFields}
                  className='text-sm font-medium text-[#475467]'
                />
                <Input
                  label='E-mail Address'
                  {...register('personal_info.email', {
                    required: 'Email is required',
                    pattern: emailRegex
                  })}
                  autoComplete='email'
                  errors={errors}
                  touched={touchedFields}
                  className='text-sm font-medium text-[#475467]'
                />
                {/* <Input
                  label='Phone'
                  {...register('personal_info.phone', {
                    required: 'USADF Id is required'
                  })}
                  autoComplete='phone'
                  errors={errors}
                  touched={touchedFields}
                  className='text-sm font-medium text-[#475467]'
                /> */}
                <Input
                  placeholder='Enter your USADF Unique Identifier'
                  label='Unique Identifier'
                  {...register('personal_info.usadf_id', {
                    required: 'USADF Unique Identifier is required'
                  })}
                  autoComplete='id'
                  errors={errors}
                  touched={touchedFields}
                  className='text-sm font-medium text-[#475467]'
                />

                <Button className='mt-6 bg-primary-active' onClick={handleSubmit(verifyUser)}>
                  Next
                </Button>
                {verificationError && (
                  <div className='pt-1 pl-2 text-rose-500 text-small-regular'>
                    <span>{verificationError}</span>
                  </div>
                )}
              </div>
            )}
          </ConnectForm>
        </>
      )}
    </div>
  );
};

export default UserDetails;
