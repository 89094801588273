import clsx from 'clsx';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import ChevronDown from '../icons/chevron-down';
import Plus from '../icons/plus';
import Print from '../icons/print';

const actions = {
  add: Plus,
  print: Print,
  download: ChevronDown
};

function buttonStyle(variant?: 'primary' | 'secondary' | 'outline' | 'danger' | 'inherit'): string {
  switch (variant) {
    case 'primary':
      return 'bg-primary text-white border-primary';
    case 'danger':
      return 'bg-red text-white border-red';
    case 'secondary':
      return 'bg-primary-background text-primary border-primary-background';
    case 'outline':
      return 'bg-transparent border border-primary text-primary';
    case 'inherit':
      return 'bg-transparent border-primary text-typo-base';
    default:
      return '';
  }
}

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'outline' | 'danger' | 'inherit';
  fullWidth?: boolean;

  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  to?: string;

  disabled?: boolean;
  action?: keyof typeof actions;
  className?: string;
  large?: boolean;
  type?: 'button' | 'submit';
  children?: React.ReactNode;
};

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  fullWidth,
  onClick,
  to,
  action,
  children,
  className,
  large = false,
  disabled,
  ...props
}) => {
  const buttonStyles = clsx(
    'cursor-pointer leading-none font-medium flex flex-row justify-center items-center px-4 rounded-lg transition-opacity border border-transparent disabled:cursor-not-allowed disabled:opacity-40 leading-none',
    fullWidth && 'w-full',
    buttonStyle(variant),
    disabled ? 'opacity-40' : 'hover:opacity-80',
    large ? 'text-base h-12' : 'text-body h-10',
    className
  );
  // const hasIcon = !!action
  const Icon = action ? actions[action] : null;
  if (!disabled && to) {
    return (
      <Link to={to} className={buttonStyles} {...props}>
        {/* {Icon && Icon} */}
        {children}
      </Link>
    );
  }

  return (
    <button
      className={buttonStyles}
      onClick={(e: React.MouseEvent<HTMLElement>) => onClick?.(e)}
      {...props}
      disabled={disabled}
    >
      {Icon && <Icon className='w-4 h-4 mr-2' />}
      {children}
    </button>
  );
};
