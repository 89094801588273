/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * USADF REST API
 * OpenAPI spec version: v1
 */
import {useQuery, useMutation} from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query';
import type {
  Lesson,
  DefaultErrorResponse,
  Business,
  Country,
  Course,
  Exam,
  UnprocessableEntityResponse,
  LessonWithMaterial,
  User,
  Question,
  ActiveLessonMaterial,
  Options,
  Tier,
  UserCourseDetails,
  ExamDetails,
  UserTier,
  UserAnswer,
  UserLogin,
  LessonMaterial,
  LoginResponse,
  UserOnboarding,
  UserRegister,
  Reporting,
  Sector,
  UserVerification
} from './models';
import {useFetcher} from '../lib/util/fetcher';
import type {ErrorType} from '../lib/util/fetcher';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y
  ? 1
  : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{[Q in P]: T[P]}, {-readonly [Q in P]: T[P]}, P>;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const useGetActiveLessonsHook = () => {
  const getActiveLessons = useFetcher<Lesson[]>();

  return (signal?: AbortSignal) => {
    return getActiveLessons({url: `/active/lessons`, method: 'get', signal});
  };
};

export const getGetActiveLessonsQueryKey = () => [`/active/lessons`] as const;

export const useGetActiveLessonsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveLessonsQueryKey();

  const getActiveLessons = useGetActiveLessonsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>> = ({
    signal
  }) => getActiveLessons(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetActiveLessonsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>
>;
export type GetActiveLessonsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetActiveLessons = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetActiveLessonsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetActiveLessonsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetBusinessBusinessIdHook = () => {
  const getBusinessBusinessId = useFetcher<Business>();

  return (businessId: string, signal?: AbortSignal) => {
    return getBusinessBusinessId({url: `/business/${businessId}`, method: 'get', signal});
  };
};

export const getGetBusinessBusinessIdQueryKey = (businessId: string) =>
  [`/business/${businessId}`] as const;

export const useGetBusinessBusinessIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  businessId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessBusinessIdQueryKey(businessId);

  const getBusinessBusinessId = useGetBusinessBusinessIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>
  > = ({signal}) => getBusinessBusinessId(businessId, signal);

  return {queryKey, queryFn, enabled: !!businessId, ...queryOptions};
};

export type GetBusinessBusinessIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>
>;
export type GetBusinessBusinessIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetBusinessBusinessId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  businessId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBusinessBusinessIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetBusinessBusinessIdQueryOptions(businessId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCountryHook = () => {
  const getCountry = useFetcher<Country[]>();

  return (signal?: AbortSignal) => {
    return getCountry({url: `/country`, method: 'get', signal});
  };
};

export const getGetCountryQueryKey = () => [`/country`] as const;

export const useGetCountryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCountryQueryKey();

  const getCountry = useGetCountryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>> = ({
    signal
  }) => getCountry(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetCountryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>
>;
export type GetCountryQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCountry = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCountryHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCountryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCountryCountryIdHook = () => {
  const getCountryCountryId = useFetcher<Country>();

  return (countryId: string, signal?: AbortSignal) => {
    return getCountryCountryId({url: `/country/${countryId}`, method: 'get', signal});
  };
};

export const getGetCountryCountryIdQueryKey = (countryId: string) =>
  [`/country/${countryId}`] as const;

export const useGetCountryCountryIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  countryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCountryCountryIdQueryKey(countryId);

  const getCountryCountryId = useGetCountryCountryIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>
  > = ({signal}) => getCountryCountryId(countryId, signal);

  return {queryKey, queryFn, enabled: !!countryId, ...queryOptions};
};

export type GetCountryCountryIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>
>;
export type GetCountryCountryIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCountryCountryId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  countryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCountryCountryIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCountryCountryIdQueryOptions(countryId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCourseCourseIdHook = () => {
  const getCourseCourseId = useFetcher<Course>();

  return (courseId: string, signal?: AbortSignal) => {
    return getCourseCourseId({url: `/course/${courseId}`, method: 'get', signal});
  };
};

export const getGetCourseCourseIdQueryKey = (courseId: string) => [`/course/${courseId}`] as const;

export const useGetCourseCourseIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCourseCourseIdQueryKey(courseId);

  const getCourseCourseId = useGetCourseCourseIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>
  > = ({signal}) => getCourseCourseId(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetCourseCourseIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>
>;
export type GetCourseCourseIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCourseCourseId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCourseCourseIdQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCourseCourseIdExamHook = () => {
  const getCourseCourseIdExam = useFetcher<Exam[]>();

  return (courseId: string, signal?: AbortSignal) => {
    return getCourseCourseIdExam({url: `/course/${courseId}/exam`, method: 'get', signal});
  };
};

export const getGetCourseCourseIdExamQueryKey = (courseId: string) =>
  [`/course/${courseId}/exam`] as const;

export const useGetCourseCourseIdExamQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCourseCourseIdExamQueryKey(courseId);

  const getCourseCourseIdExam = useGetCourseCourseIdExamHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>
  > = ({signal}) => getCourseCourseIdExam(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetCourseCourseIdExamQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>
>;
export type GetCourseCourseIdExamQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCourseCourseIdExam = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCourseCourseIdExamQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostCourseCourseIdExamHook = () => {
  const postCourseCourseIdExam = useFetcher<Exam>();

  return (courseId: string, exam: NonReadonly<Exam>) => {
    return postCourseCourseIdExam({
      url: `/course/${courseId}/exam`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: exam
    });
  };
};

export const usePostCourseCourseIdExamMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdExamHook>>>,
    TError,
    {courseId: string; data: NonReadonly<Exam>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdExamHook>>>,
  TError,
  {courseId: string; data: NonReadonly<Exam>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postCourseCourseIdExam = usePostCourseCourseIdExamHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdExamHook>>>,
    {courseId: string; data: NonReadonly<Exam>}
  > = props => {
    const {courseId, data} = props ?? {};

    return postCourseCourseIdExam(courseId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostCourseCourseIdExamMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdExamHook>>>
>;
export type PostCourseCourseIdExamMutationBody = NonReadonly<Exam>;
export type PostCourseCourseIdExamMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostCourseCourseIdExam = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdExamHook>>>,
    TError,
    {courseId: string; data: NonReadonly<Exam>},
    TContext
  >;
}) => {
  const mutationOptions = usePostCourseCourseIdExamMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetCourseCourseIdLessonHook = () => {
  const getCourseCourseIdLesson = useFetcher<LessonWithMaterial[]>();

  return (courseId: string, signal?: AbortSignal) => {
    return getCourseCourseIdLesson({url: `/course/${courseId}/lesson`, method: 'get', signal});
  };
};

export const getGetCourseCourseIdLessonQueryKey = (courseId: string) =>
  [`/course/${courseId}/lesson`] as const;

export const useGetCourseCourseIdLessonQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCourseCourseIdLessonQueryKey(courseId);

  const getCourseCourseIdLesson = useGetCourseCourseIdLessonHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>
  > = ({signal}) => getCourseCourseIdLesson(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetCourseCourseIdLessonQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>
>;
export type GetCourseCourseIdLessonQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCourseCourseIdLesson = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCourseCourseIdLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCourseCourseIdLessonQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostCourseCourseIdLessonHook = () => {
  const postCourseCourseIdLesson = useFetcher<Lesson>();

  return (courseId: string, lesson: NonReadonly<Lesson>) => {
    return postCourseCourseIdLesson({
      url: `/course/${courseId}/lesson`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: lesson
    });
  };
};

export const usePostCourseCourseIdLessonMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdLessonHook>>>,
    TError,
    {courseId: string; data: NonReadonly<Lesson>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdLessonHook>>>,
  TError,
  {courseId: string; data: NonReadonly<Lesson>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postCourseCourseIdLesson = usePostCourseCourseIdLessonHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdLessonHook>>>,
    {courseId: string; data: NonReadonly<Lesson>}
  > = props => {
    const {courseId, data} = props ?? {};

    return postCourseCourseIdLesson(courseId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostCourseCourseIdLessonMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdLessonHook>>>
>;
export type PostCourseCourseIdLessonMutationBody = NonReadonly<Lesson>;
export type PostCourseCourseIdLessonMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostCourseCourseIdLesson = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCourseCourseIdLessonHook>>>,
    TError,
    {courseId: string; data: NonReadonly<Lesson>},
    TContext
  >;
}) => {
  const mutationOptions = usePostCourseCourseIdLessonMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetCoursesHook = () => {
  const getCourses = useFetcher<Course[]>();

  return (signal?: AbortSignal) => {
    return getCourses({url: `/courses`, method: 'get', signal});
  };
};

export const getGetCoursesQueryKey = () => [`/courses`] as const;

export const useGetCoursesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCoursesQueryKey();

  const getCourses = useGetCoursesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>> = ({
    signal
  }) => getCourses(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetCoursesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>
>;
export type GetCoursesQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCourses = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCoursesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCoursesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetCurrentUserHook = () => {
  const getCurrentUser = useFetcher<User>();

  return (signal?: AbortSignal) => {
    return getCurrentUser({url: `/current/user`, method: 'get', signal});
  };
};

export const getGetCurrentUserQueryKey = () => [`/current/user`] as const;

export const useGetCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserQueryKey();

  const getCurrentUser = useGetCurrentUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>> = ({
    signal
  }) => getCurrentUser(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>
>;
export type GetCurrentUserQueryError = ErrorType<DefaultErrorResponse>;

export const useGetCurrentUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCurrentUserHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetCurrentUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetExamExamIdQuestionsHook = () => {
  const getExamExamIdQuestions = useFetcher<Question[]>();

  return (examId: string, signal?: AbortSignal) => {
    return getExamExamIdQuestions({url: `/exam/${examId}/questions`, method: 'get', signal});
  };
};

export const getGetExamExamIdQuestionsQueryKey = (examId: string) =>
  [`/exam/${examId}/questions`] as const;

export const useGetExamExamIdQuestionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExamExamIdQuestionsQueryKey(examId);

  const getExamExamIdQuestions = useGetExamExamIdQuestionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>
  > = ({signal}) => getExamExamIdQuestions(examId, signal);

  return {queryKey, queryFn, enabled: !!examId, ...queryOptions};
};

export type GetExamExamIdQuestionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>
>;
export type GetExamExamIdQuestionsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetExamExamIdQuestions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetExamExamIdQuestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetExamExamIdQuestionsQueryOptions(examId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostExamExamIdQuestionsHook = () => {
  const postExamExamIdQuestions = useFetcher<Question>();

  return (examId: string, question: NonReadonly<Question[]>) => {
    return postExamExamIdQuestions({
      url: `/exam/${examId}/questions`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: question
    });
  };
};

export const usePostExamExamIdQuestionsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostExamExamIdQuestionsHook>>>,
    TError,
    {examId: string; data: NonReadonly<Question[]>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostExamExamIdQuestionsHook>>>,
  TError,
  {examId: string; data: NonReadonly<Question[]>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postExamExamIdQuestions = usePostExamExamIdQuestionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostExamExamIdQuestionsHook>>>,
    {examId: string; data: NonReadonly<Question[]>}
  > = props => {
    const {examId, data} = props ?? {};

    return postExamExamIdQuestions(examId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostExamExamIdQuestionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostExamExamIdQuestionsHook>>>
>;
export type PostExamExamIdQuestionsMutationBody = NonReadonly<Question[]>;
export type PostExamExamIdQuestionsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostExamExamIdQuestions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostExamExamIdQuestionsHook>>>,
    TError,
    {examId: string; data: NonReadonly<Question[]>},
    TContext
  >;
}) => {
  const mutationOptions = usePostExamExamIdQuestionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetGotbotBusinessBusinessIdHook = () => {
  const getGotbotBusinessBusinessId = useFetcher<Business>();

  return (businessId: string, signal?: AbortSignal) => {
    return getGotbotBusinessBusinessId({
      url: `/gotbot/business/${businessId}`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotBusinessBusinessIdQueryKey = (businessId: string) =>
  [`/gotbot/business/${businessId}`] as const;

export const useGetGotbotBusinessBusinessIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  businessId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotBusinessBusinessIdQueryKey(businessId);

  const getGotbotBusinessBusinessId = useGetGotbotBusinessBusinessIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>
  > = ({signal}) => getGotbotBusinessBusinessId(businessId, signal);

  return {queryKey, queryFn, enabled: !!businessId, ...queryOptions};
};

export type GetGotbotBusinessBusinessIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>
>;
export type GetGotbotBusinessBusinessIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotBusinessBusinessId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  businessId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotBusinessBusinessIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotBusinessBusinessIdQueryOptions(businessId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve a specific course
 */
export const useGetGotbotCourseCourseIdHook = () => {
  const getGotbotCourseCourseId = useFetcher<Course>();

  return (courseId: string, signal?: AbortSignal) => {
    return getGotbotCourseCourseId({url: `/gotbot/course/${courseId}`, method: 'get', signal});
  };
};

export const getGetGotbotCourseCourseIdQueryKey = (courseId: string) =>
  [`/gotbot/course/${courseId}`] as const;

export const useGetGotbotCourseCourseIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotCourseCourseIdQueryKey(courseId);

  const getGotbotCourseCourseId = useGetGotbotCourseCourseIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>
  > = ({signal}) => getGotbotCourseCourseId(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetGotbotCourseCourseIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>
>;
export type GetGotbotCourseCourseIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotCourseCourseId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotCourseCourseIdQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all exams in  course
 */
export const useGetGotbotCourseCourseIdExamHook = () => {
  const getGotbotCourseCourseIdExam = useFetcher<Exam[]>();

  return (courseId: string, signal?: AbortSignal) => {
    return getGotbotCourseCourseIdExam({
      url: `/gotbot/course/${courseId}/exam`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotCourseCourseIdExamQueryKey = (courseId: string) =>
  [`/gotbot/course/${courseId}/exam`] as const;

export const useGetGotbotCourseCourseIdExamQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotCourseCourseIdExamQueryKey(courseId);

  const getGotbotCourseCourseIdExam = useGetGotbotCourseCourseIdExamHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>
  > = ({signal}) => getGotbotCourseCourseIdExam(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetGotbotCourseCourseIdExamQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>
>;
export type GetGotbotCourseCourseIdExamQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotCourseCourseIdExam = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotCourseCourseIdExamQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all lessons for a course
 */
export const useGetGotbotCourseCourseIdLessonHook = () => {
  const getGotbotCourseCourseIdLesson = useFetcher<LessonWithMaterial[]>();

  return (courseId: string, signal?: AbortSignal) => {
    return getGotbotCourseCourseIdLesson({
      url: `/gotbot/course/${courseId}/lesson`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotCourseCourseIdLessonQueryKey = (courseId: string) =>
  [`/gotbot/course/${courseId}/lesson`] as const;

export const useGetGotbotCourseCourseIdLessonQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotCourseCourseIdLessonQueryKey(courseId);

  const getGotbotCourseCourseIdLesson = useGetGotbotCourseCourseIdLessonHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>
  > = ({signal}) => getGotbotCourseCourseIdLesson(courseId, signal);

  return {queryKey, queryFn, enabled: !!courseId, ...queryOptions};
};

export type GetGotbotCourseCourseIdLessonQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>
>;
export type GetGotbotCourseCourseIdLessonQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotCourseCourseIdLesson = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotCourseCourseIdLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotCourseCourseIdLessonQueryOptions(courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all courses
 */
export const useGetGotbotCoursesHook = () => {
  const getGotbotCourses = useFetcher<Course[]>();

  return (signal?: AbortSignal) => {
    return getGotbotCourses({url: `/gotbot/courses`, method: 'get', signal});
  };
};

export const getGetGotbotCoursesQueryKey = () => [`/gotbot/courses`] as const;

export const useGetGotbotCoursesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotCoursesQueryKey();

  const getGotbotCourses = useGetGotbotCoursesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>> = ({
    signal
  }) => getGotbotCourses(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetGotbotCoursesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>
>;
export type GetGotbotCoursesQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotCourses = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotCoursesHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotCoursesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all questions in exam
 */
export const useGetGotbotExamExamIdQuestionsHook = () => {
  const getGotbotExamExamIdQuestions = useFetcher<Question[]>();

  return (examId: string, signal?: AbortSignal) => {
    return getGotbotExamExamIdQuestions({
      url: `/gotbot/exam/${examId}/questions`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotExamExamIdQuestionsQueryKey = (examId: string) =>
  [`/gotbot/exam/${examId}/questions`] as const;

export const useGetGotbotExamExamIdQuestionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotExamExamIdQuestionsQueryKey(examId);

  const getGotbotExamExamIdQuestions = useGetGotbotExamExamIdQuestionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>
  > = ({signal}) => getGotbotExamExamIdQuestions(examId, signal);

  return {queryKey, queryFn, enabled: !!examId, ...queryOptions};
};

export type GetGotbotExamExamIdQuestionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>
>;
export type GetGotbotExamExamIdQuestionsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotExamExamIdQuestions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotExamExamIdQuestionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotExamExamIdQuestionsQueryOptions(examId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetGotbotLessonLessonIdLessonMaterialsHook = () => {
  const getGotbotLessonLessonIdLessonMaterials = useFetcher<ActiveLessonMaterial[]>();

  return (lessonId: string, signal?: AbortSignal) => {
    return getGotbotLessonLessonIdLessonMaterials({
      url: `/gotbot/lesson/${lessonId}/lesson-materials`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotLessonLessonIdLessonMaterialsQueryKey = (lessonId: string) =>
  [`/gotbot/lesson/${lessonId}/lesson-materials`] as const;

export const useGetGotbotLessonLessonIdLessonMaterialsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGotbotLessonLessonIdLessonMaterialsQueryKey(lessonId);

  const getGotbotLessonLessonIdLessonMaterials = useGetGotbotLessonLessonIdLessonMaterialsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>
  > = ({signal}) => getGotbotLessonLessonIdLessonMaterials(lessonId, signal);

  return {queryKey, queryFn, enabled: !!lessonId, ...queryOptions};
};

export type GetGotbotLessonLessonIdLessonMaterialsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>
>;
export type GetGotbotLessonLessonIdLessonMaterialsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotLessonLessonIdLessonMaterials = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotLessonLessonIdLessonMaterialsQueryOptions(lessonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all options in questions
 */
export const useGetGotbotQuestionQuestionIdOptionsHook = () => {
  const getGotbotQuestionQuestionIdOptions = useFetcher<Options[]>();

  return (questionId: string, signal?: AbortSignal) => {
    return getGotbotQuestionQuestionIdOptions({
      url: `/gotbot/question/${questionId}/options`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotQuestionQuestionIdOptionsQueryKey = (questionId: string) =>
  [`/gotbot/question/${questionId}/options`] as const;

export const useGetGotbotQuestionQuestionIdOptionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGotbotQuestionQuestionIdOptionsQueryKey(questionId);

  const getGotbotQuestionQuestionIdOptions = useGetGotbotQuestionQuestionIdOptionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>
  > = ({signal}) => getGotbotQuestionQuestionIdOptions(questionId, signal);

  return {queryKey, queryFn, enabled: !!questionId, ...queryOptions};
};

export type GetGotbotQuestionQuestionIdOptionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>
>;
export type GetGotbotQuestionQuestionIdOptionsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotQuestionQuestionIdOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotQuestionQuestionIdOptionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotQuestionQuestionIdOptionsQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all tiers
 */
export const useGetGotbotTiersHook = () => {
  const getGotbotTiers = useFetcher<Tier[]>();

  return (signal?: AbortSignal) => {
    return getGotbotTiers({url: `/gotbot/tiers`, method: 'get', signal});
  };
};

export const getGetGotbotTiersQueryKey = () => [`/gotbot/tiers`] as const;

export const useGetGotbotTiersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotTiersQueryKey();

  const getGotbotTiers = useGetGotbotTiersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>> = ({
    signal
  }) => getGotbotTiers(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetGotbotTiersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>
>;
export type GetGotbotTiersQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotTiers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotTiersHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotTiersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update user course details
 */
export const usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook = () => {
  const putGotbotUserUserIdCourseCourseDetailsIdCourseDetails = useFetcher<UserCourseDetails>();

  return (
    userId: string,
    courseDetailsId: string,
    userCourseDetails: NonReadonly<UserCourseDetails>
  ) => {
    return putGotbotUserUserIdCourseCourseDetailsIdCourseDetails({
      url: `/gotbot/user/${userId}/course/${courseDetailsId}/course_details`,
      method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: userCourseDetails
    });
  };
};

export const usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook>>
    >,
    TError,
    {userId: string; courseDetailsId: string; data: NonReadonly<UserCourseDetails>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook>>
  >,
  TError,
  {userId: string; courseDetailsId: string; data: NonReadonly<UserCourseDetails>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const putGotbotUserUserIdCourseCourseDetailsIdCourseDetails =
    usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook>>
    >,
    {userId: string; courseDetailsId: string; data: NonReadonly<UserCourseDetails>}
  > = props => {
    const {userId, courseDetailsId, data} = props ?? {};

    return putGotbotUserUserIdCourseCourseDetailsIdCourseDetails(userId, courseDetailsId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook>>
  >
>;
export type PutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsMutationBody =
  NonReadonly<UserCourseDetails>;
export type PutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetails = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsHook>>
    >,
    TError,
    {userId: string; courseDetailsId: string; data: NonReadonly<UserCourseDetails>},
    TContext
  >;
}) => {
  const mutationOptions =
    usePutGotbotUserUserIdCourseCourseDetailsIdCourseDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Retrieve user certificate
 */
export const useGetGotbotUserUserIdCourseCourseIdCertificateHook = () => {
  const getGotbotUserUserIdCourseCourseIdCertificate = useFetcher<unknown>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getGotbotUserUserIdCourseCourseIdCertificate({
      url: `/gotbot/user/${userId}/course/${courseId}/certificate`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotUserUserIdCourseCourseIdCertificateQueryKey = (
  userId: string,
  courseId: string
) => [`/gotbot/user/${userId}/course/${courseId}/certificate`] as const;

export const useGetGotbotUserUserIdCourseCourseIdCertificateQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGotbotUserUserIdCourseCourseIdCertificateQueryKey(userId, courseId);

  const getGotbotUserUserIdCourseCourseIdCertificate =
    useGetGotbotUserUserIdCourseCourseIdCertificateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>>
  > = ({signal}) => getGotbotUserUserIdCourseCourseIdCertificate(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetGotbotUserUserIdCourseCourseIdCertificateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>>
>;
export type GetGotbotUserUserIdCourseCourseIdCertificateQueryError =
  ErrorType<DefaultErrorResponse>;

export const useGetGotbotUserUserIdCourseCourseIdCertificate = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotUserUserIdCourseCourseIdCertificateQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve user course data by course
 */
export const useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook = () => {
  const getGotbotUserUserIdCourseCourseIdCourseDetails = useFetcher<UserCourseDetails>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getGotbotUserUserIdCourseCourseIdCourseDetails({
      url: `/gotbot/user/${userId}/course/${courseId}/course_details`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotUserUserIdCourseCourseIdCourseDetailsQueryKey = (
  userId: string,
  courseId: string
) => [`/gotbot/user/${userId}/course/${courseId}/course_details`] as const;

export const useGetGotbotUserUserIdCourseCourseIdCourseDetailsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetGotbotUserUserIdCourseCourseIdCourseDetailsQueryKey(userId, courseId);

  const getGotbotUserUserIdCourseCourseIdCourseDetails =
    useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>
  > = ({signal}) => getGotbotUserUserIdCourseCourseIdCourseDetails(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetGotbotUserUserIdCourseCourseIdCourseDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>
>;
export type GetGotbotUserUserIdCourseCourseIdCourseDetailsQueryError =
  ErrorType<DefaultErrorResponse>;

export const useGetGotbotUserUserIdCourseCourseIdCourseDetails = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotUserUserIdCourseCourseIdCourseDetailsQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Enrole user into course
 */
export const usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook = () => {
  const postGotbotUserUserIdCourseCourseIdCourseDetails = useFetcher<UserCourseDetails>();

  return (userId: string, courseId: string) => {
    return postGotbotUserUserIdCourseCourseIdCourseDetails({
      url: `/gotbot/user/${userId}/course/${courseId}/course_details`,
      method: 'post'
    });
  };
};

export const usePostGotbotUserUserIdCourseCourseIdCourseDetailsMutationOptions = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
    TError,
    {userId: string; courseId: string},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
  TError,
  {userId: string; courseId: string},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postGotbotUserUserIdCourseCourseIdCourseDetails =
    usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
    {userId: string; courseId: string}
  > = props => {
    const {userId, courseId} = props ?? {};

    return postGotbotUserUserIdCourseCourseIdCourseDetails(userId, courseId);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostGotbotUserUserIdCourseCourseIdCourseDetailsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>
>;

export type PostGotbotUserUserIdCourseCourseIdCourseDetailsMutationError =
  ErrorType<DefaultErrorResponse>;

export const usePostGotbotUserUserIdCourseCourseIdCourseDetails = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdCourseCourseIdCourseDetailsHook>>>,
    TError,
    {userId: string; courseId: string},
    TContext
  >;
}) => {
  const mutationOptions =
    usePostGotbotUserUserIdCourseCourseIdCourseDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Retrieve all user course data
 */
export const useGetGotbotUserUserIdCourseDetailsHook = () => {
  const getGotbotUserUserIdCourseDetails = useFetcher<UserCourseDetails[]>();

  return (userId: string, signal?: AbortSignal) => {
    return getGotbotUserUserIdCourseDetails({
      url: `/gotbot/user/${userId}/course_details`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotUserUserIdCourseDetailsQueryKey = (userId: string) =>
  [`/gotbot/user/${userId}/course_details`] as const;

export const useGetGotbotUserUserIdCourseDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotUserUserIdCourseDetailsQueryKey(userId);

  const getGotbotUserUserIdCourseDetails = useGetGotbotUserUserIdCourseDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>
  > = ({signal}) => getGotbotUserUserIdCourseDetails(userId, signal);

  return {queryKey, queryFn, enabled: !!userId, ...queryOptions};
};

export type GetGotbotUserUserIdCourseDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>
>;
export type GetGotbotUserUserIdCourseDetailsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotUserUserIdCourseDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotUserUserIdCourseDetailsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve all exam details
 */
export const useGetGotbotUserUserIdExamExamIdExamDetailsHook = () => {
  const getGotbotUserUserIdExamExamIdExamDetails = useFetcher<ExamDetails>();

  return (userId: string, examId: string, signal?: AbortSignal) => {
    return getGotbotUserUserIdExamExamIdExamDetails({
      url: `/gotbot/user/${userId}/exam/${examId}/exam_details`,
      method: 'get',
      signal
    });
  };
};

export const getGetGotbotUserUserIdExamExamIdExamDetailsQueryKey = (
  userId: string,
  examId: string
) => [`/gotbot/user/${userId}/exam/${examId}/exam_details`] as const;

export const useGetGotbotUserUserIdExamExamIdExamDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetGotbotUserUserIdExamExamIdExamDetailsQueryKey(userId, examId);

  const getGotbotUserUserIdExamExamIdExamDetails =
    useGetGotbotUserUserIdExamExamIdExamDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>
  > = ({signal}) => getGotbotUserUserIdExamExamIdExamDetails(userId, examId, signal);

  return {queryKey, queryFn, enabled: !!(userId && examId), ...queryOptions};
};

export type GetGotbotUserUserIdExamExamIdExamDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>
>;
export type GetGotbotUserUserIdExamExamIdExamDetailsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotUserUserIdExamExamIdExamDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdExamExamIdExamDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotUserUserIdExamExamIdExamDetailsQueryOptions(
    userId,
    examId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create exam details
 */
export const usePostGotbotUserUserIdExamExamIdExamDetailsHook = () => {
  const postGotbotUserUserIdExamExamIdExamDetails = useFetcher<ExamDetails>();

  return (userId: string, examId: string, examDetails: NonReadonly<ExamDetails>) => {
    return postGotbotUserUserIdExamExamIdExamDetails({
      url: `/gotbot/user/${userId}/exam/${examId}/exam_details`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: examDetails
    });
  };
};

export const usePostGotbotUserUserIdExamExamIdExamDetailsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamExamIdExamDetailsHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamExamIdExamDetailsHook>>>,
  TError,
  {userId: string; examId: string; data: NonReadonly<ExamDetails>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postGotbotUserUserIdExamExamIdExamDetails =
    usePostGotbotUserUserIdExamExamIdExamDetailsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamExamIdExamDetailsHook>>>,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>}
  > = props => {
    const {userId, examId, data} = props ?? {};

    return postGotbotUserUserIdExamExamIdExamDetails(userId, examId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostGotbotUserUserIdExamExamIdExamDetailsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamExamIdExamDetailsHook>>>
>;
export type PostGotbotUserUserIdExamExamIdExamDetailsMutationBody = NonReadonly<ExamDetails>;
export type PostGotbotUserUserIdExamExamIdExamDetailsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostGotbotUserUserIdExamExamIdExamDetails = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamExamIdExamDetailsHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>},
    TContext
  >;
}) => {
  const mutationOptions = usePostGotbotUserUserIdExamExamIdExamDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Retrieve user tiers
 */
export const useGetGotbotUserUserIdTierHook = () => {
  const getGotbotUserUserIdTier = useFetcher<UserTier[]>();

  return (userId: string, signal?: AbortSignal) => {
    return getGotbotUserUserIdTier({url: `/gotbot/user/${userId}/tier`, method: 'get', signal});
  };
};

export const getGetGotbotUserUserIdTierQueryKey = (userId: string) =>
  [`/gotbot/user/${userId}/tier`] as const;

export const useGetGotbotUserUserIdTierQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGotbotUserUserIdTierQueryKey(userId);

  const getGotbotUserUserIdTier = useGetGotbotUserUserIdTierHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>
  > = ({signal}) => getGotbotUserUserIdTier(userId, signal);

  return {queryKey, queryFn, enabled: !!userId, ...queryOptions};
};

export type GetGotbotUserUserIdTierQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>
>;
export type GetGotbotUserUserIdTierQueryError = ErrorType<DefaultErrorResponse>;

export const useGetGotbotUserUserIdTier = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetGotbotUserUserIdTierHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetGotbotUserUserIdTierQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add user to a tier
 */
export const usePostGotbotUserUserIdTierTierIdHook = () => {
  const postGotbotUserUserIdTierTierId = useFetcher<UserTier>();

  return (userId: string, tierId: string) => {
    return postGotbotUserUserIdTierTierId({
      url: `/gotbot/user/${userId}/tier/${tierId}`,
      method: 'post'
    });
  };
};

export const usePostGotbotUserUserIdTierTierIdMutationOptions = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdTierTierIdHook>>>,
    TError,
    {userId: string; tierId: string},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdTierTierIdHook>>>,
  TError,
  {userId: string; tierId: string},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postGotbotUserUserIdTierTierId = usePostGotbotUserUserIdTierTierIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdTierTierIdHook>>>,
    {userId: string; tierId: string}
  > = props => {
    const {userId, tierId} = props ?? {};

    return postGotbotUserUserIdTierTierId(userId, tierId);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostGotbotUserUserIdTierTierIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdTierTierIdHook>>>
>;

export type PostGotbotUserUserIdTierTierIdMutationError = ErrorType<DefaultErrorResponse>;

export const usePostGotbotUserUserIdTierTierId = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdTierTierIdHook>>>,
    TError,
    {userId: string; tierId: string},
    TContext
  >;
}) => {
  const mutationOptions = usePostGotbotUserUserIdTierTierIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Submit user answers
 */
export const usePostGotbotUserUserIdExamIdExamAnswersHook = () => {
  const postGotbotUserUserIdExamIdExamAnswers = useFetcher<UserAnswer[]>();

  return (userId: string, examId: string, userAnswer: NonReadonly<UserAnswer[]>) => {
    return postGotbotUserUserIdExamIdExamAnswers({
      url: `/gotbot/user/${userId}/${examId}/exam/answers`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userAnswer
    });
  };
};

export const usePostGotbotUserUserIdExamIdExamAnswersMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamIdExamAnswersHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<UserAnswer[]>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamIdExamAnswersHook>>>,
  TError,
  {userId: string; examId: string; data: NonReadonly<UserAnswer[]>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postGotbotUserUserIdExamIdExamAnswers = usePostGotbotUserUserIdExamIdExamAnswersHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamIdExamAnswersHook>>>,
    {userId: string; examId: string; data: NonReadonly<UserAnswer[]>}
  > = props => {
    const {userId, examId, data} = props ?? {};

    return postGotbotUserUserIdExamIdExamAnswers(userId, examId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostGotbotUserUserIdExamIdExamAnswersMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamIdExamAnswersHook>>>
>;
export type PostGotbotUserUserIdExamIdExamAnswersMutationBody = NonReadonly<UserAnswer[]>;
export type PostGotbotUserUserIdExamIdExamAnswersMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostGotbotUserUserIdExamIdExamAnswers = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotUserUserIdExamIdExamAnswersHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<UserAnswer[]>},
    TContext
  >;
}) => {
  const mutationOptions = usePostGotbotUserUserIdExamIdExamAnswersMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary Verify user information
 */
export const usePostGotbotVerifyUserHook = () => {
  const postGotbotVerifyUser = useFetcher<User>();

  return (userLogin: UserLogin) => {
    return postGotbotVerifyUser({
      url: `/gotbot/verify-user`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userLogin
    });
  };
};

export const usePostGotbotVerifyUserMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotVerifyUserHook>>>,
    TError,
    {data: UserLogin},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotVerifyUserHook>>>,
  TError,
  {data: UserLogin},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postGotbotVerifyUser = usePostGotbotVerifyUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotVerifyUserHook>>>,
    {data: UserLogin}
  > = props => {
    const {data} = props ?? {};

    return postGotbotVerifyUser(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostGotbotVerifyUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostGotbotVerifyUserHook>>>
>;
export type PostGotbotVerifyUserMutationBody = UserLogin;
export type PostGotbotVerifyUserMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostGotbotVerifyUser = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostGotbotVerifyUserHook>>>,
    TError,
    {data: UserLogin},
    TContext
  >;
}) => {
  const mutationOptions = usePostGotbotVerifyUserMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetHealthzHook = () => {
  const getHealthz = useFetcher<unknown>();

  return (signal?: AbortSignal) => {
    return getHealthz({url: `/healthz`, method: 'get', signal});
  };
};

export const getGetHealthzQueryKey = () => [`/healthz`] as const;

export const useGetHealthzQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHealthzQueryKey();

  const getHealthz = useGetHealthzHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>> = ({
    signal
  }) => getHealthz(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetHealthzQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>
>;
export type GetHealthzQueryError = ErrorType<DefaultErrorResponse>;

export const useGetHealthz = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHealthzHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetHealthzQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetImagesPathHook = () => {
  const getImagesPath = useFetcher<unknown>();

  return (path: string, signal?: AbortSignal) => {
    return getImagesPath({url: `/images/${path}`, method: 'get', signal});
  };
};

export const getGetImagesPathQueryKey = (path: string) => [`/images/${path}`] as const;

export const useGetImagesPathQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  path: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetImagesPathQueryKey(path);

  const getImagesPath = useGetImagesPathHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>> = ({
    signal
  }) => getImagesPath(path, signal);

  return {queryKey, queryFn, enabled: !!path, ...queryOptions};
};

export type GetImagesPathQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>
>;
export type GetImagesPathQueryError = ErrorType<DefaultErrorResponse>;

export const useGetImagesPath = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  path: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetImagesPathHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetImagesPathQueryOptions(path, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetLessonMaterialLessonIdHook = () => {
  const getLessonMaterialLessonId = useFetcher<LessonMaterial>();

  return (lessonId: string, signal?: AbortSignal) => {
    return getLessonMaterialLessonId({url: `/lesson-material/${lessonId}`, method: 'get', signal});
  };
};

export const getGetLessonMaterialLessonIdQueryKey = (lessonId: string) =>
  [`/lesson-material/${lessonId}`] as const;

export const useGetLessonMaterialLessonIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLessonMaterialLessonIdQueryKey(lessonId);

  const getLessonMaterialLessonId = useGetLessonMaterialLessonIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>
  > = ({signal}) => getLessonMaterialLessonId(lessonId, signal);

  return {queryKey, queryFn, enabled: !!lessonId, ...queryOptions};
};

export type GetLessonMaterialLessonIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>
>;
export type GetLessonMaterialLessonIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetLessonMaterialLessonId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonMaterialLessonIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetLessonMaterialLessonIdQueryOptions(lessonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetLessonLessonIdHook = () => {
  const getLessonLessonId = useFetcher<Lesson>();

  return (lessonId: string, signal?: AbortSignal) => {
    return getLessonLessonId({url: `/lesson/${lessonId}`, method: 'get', signal});
  };
};

export const getGetLessonLessonIdQueryKey = (lessonId: string) => [`/lesson/${lessonId}`] as const;

export const useGetLessonLessonIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLessonLessonIdQueryKey(lessonId);

  const getLessonLessonId = useGetLessonLessonIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>
  > = ({signal}) => getLessonLessonId(lessonId, signal);

  return {queryKey, queryFn, enabled: !!lessonId, ...queryOptions};
};

export type GetLessonLessonIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>
>;
export type GetLessonLessonIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetLessonLessonId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetLessonLessonIdQueryOptions(lessonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetLessonLessonIdExamHook = () => {
  const getLessonLessonIdExam = useFetcher<Exam>();

  return (lessonId: string, signal?: AbortSignal) => {
    return getLessonLessonIdExam({url: `/lesson/${lessonId}/exam`, method: 'get', signal});
  };
};

export const getGetLessonLessonIdExamQueryKey = (lessonId: string) =>
  [`/lesson/${lessonId}/exam`] as const;

export const useGetLessonLessonIdExamQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLessonLessonIdExamQueryKey(lessonId);

  const getLessonLessonIdExam = useGetLessonLessonIdExamHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>
  > = ({signal}) => getLessonLessonIdExam(lessonId, signal);

  return {queryKey, queryFn, enabled: !!lessonId, ...queryOptions};
};

export type GetLessonLessonIdExamQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>
>;
export type GetLessonLessonIdExamQueryError = ErrorType<DefaultErrorResponse>;

export const useGetLessonLessonIdExam = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdExamHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetLessonLessonIdExamQueryOptions(lessonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetLessonLessonIdLessonMaterialsHook = () => {
  const getLessonLessonIdLessonMaterials = useFetcher<LessonMaterial[]>();

  return (lessonId: string, signal?: AbortSignal) => {
    return getLessonLessonIdLessonMaterials({
      url: `/lesson/${lessonId}/lesson-materials`,
      method: 'get',
      signal
    });
  };
};

export const getGetLessonLessonIdLessonMaterialsQueryKey = (lessonId: string) =>
  [`/lesson/${lessonId}/lesson-materials`] as const;

export const useGetLessonLessonIdLessonMaterialsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLessonLessonIdLessonMaterialsQueryKey(lessonId);

  const getLessonLessonIdLessonMaterials = useGetLessonLessonIdLessonMaterialsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>
  > = ({signal}) => getLessonLessonIdLessonMaterials(lessonId, signal);

  return {queryKey, queryFn, enabled: !!lessonId, ...queryOptions};
};

export type GetLessonLessonIdLessonMaterialsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>
>;
export type GetLessonLessonIdLessonMaterialsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetLessonLessonIdLessonMaterials = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetLessonLessonIdLessonMaterialsQueryOptions(lessonId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostLessonLessonIdLessonMaterialsHook = () => {
  const postLessonLessonIdLessonMaterials = useFetcher<ActiveLessonMaterial>();

  return (lessonId: string, activeLessonMaterial: NonReadonly<ActiveLessonMaterial>) => {
    return postLessonLessonIdLessonMaterials({
      url: `/lesson/${lessonId}/lesson-materials`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: activeLessonMaterial
    });
  };
};

export const usePostLessonLessonIdLessonMaterialsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLessonLessonIdLessonMaterialsHook>>>,
    TError,
    {lessonId: string; data: NonReadonly<ActiveLessonMaterial>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostLessonLessonIdLessonMaterialsHook>>>,
  TError,
  {lessonId: string; data: NonReadonly<ActiveLessonMaterial>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postLessonLessonIdLessonMaterials = usePostLessonLessonIdLessonMaterialsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostLessonLessonIdLessonMaterialsHook>>>,
    {lessonId: string; data: NonReadonly<ActiveLessonMaterial>}
  > = props => {
    const {lessonId, data} = props ?? {};

    return postLessonLessonIdLessonMaterials(lessonId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostLessonLessonIdLessonMaterialsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostLessonLessonIdLessonMaterialsHook>>>
>;
export type PostLessonLessonIdLessonMaterialsMutationBody = NonReadonly<ActiveLessonMaterial>;
export type PostLessonLessonIdLessonMaterialsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostLessonLessonIdLessonMaterials = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLessonLessonIdLessonMaterialsHook>>>,
    TError,
    {lessonId: string; data: NonReadonly<ActiveLessonMaterial>},
    TContext
  >;
}) => {
  const mutationOptions = usePostLessonLessonIdLessonMaterialsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostLoginHook = () => {
  const postLogin = useFetcher<LoginResponse>();

  return (userLogin: UserLogin) => {
    return postLogin({
      url: `/login`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userLogin
    });
  };
};

export const usePostLoginMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    TError,
    {data: UserLogin},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
  TError,
  {data: UserLogin},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postLogin = usePostLoginHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    {data: UserLogin}
  > = props => {
    const {data} = props ?? {};

    return postLogin(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostLoginMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>
>;
export type PostLoginMutationBody = UserLogin;
export type PostLoginMutationError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>;

export const usePostLogin = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostLoginHook>>>,
    TError,
    {data: UserLogin},
    TContext
  >;
}) => {
  const mutationOptions = usePostLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostOnboardingHook = () => {
  const postOnboarding = useFetcher<LoginResponse>();

  return (userOnboarding: UserOnboarding) => {
    return postOnboarding({
      url: `/onboarding`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userOnboarding
    });
  };
};

export const usePostOnboardingMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostOnboardingHook>>>,
    TError,
    {data: UserOnboarding},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostOnboardingHook>>>,
  TError,
  {data: UserOnboarding},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postOnboarding = usePostOnboardingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostOnboardingHook>>>,
    {data: UserOnboarding}
  > = props => {
    const {data} = props ?? {};

    return postOnboarding(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostOnboardingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostOnboardingHook>>>
>;
export type PostOnboardingMutationBody = UserOnboarding;
export type PostOnboardingMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostOnboarding = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostOnboardingHook>>>,
    TError,
    {data: UserOnboarding},
    TContext
  >;
}) => {
  const mutationOptions = usePostOnboardingMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetOptionsOptionsIdHook = () => {
  const getOptionsOptionsId = useFetcher<Options>();

  return (optionsId: string, signal?: AbortSignal) => {
    return getOptionsOptionsId({url: `/options/${optionsId}`, method: 'get', signal});
  };
};

export const getGetOptionsOptionsIdQueryKey = (optionsId: string) =>
  [`/options/${optionsId}`] as const;

export const useGetOptionsOptionsIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  optionsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOptionsOptionsIdQueryKey(optionsId);

  const getOptionsOptionsId = useGetOptionsOptionsIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>
  > = ({signal}) => getOptionsOptionsId(optionsId, signal);

  return {queryKey, queryFn, enabled: !!optionsId, ...queryOptions};
};

export type GetOptionsOptionsIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>
>;
export type GetOptionsOptionsIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetOptionsOptionsId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  optionsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetOptionsOptionsIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetOptionsOptionsIdQueryOptions(optionsId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetQuestionQuestionIdHook = () => {
  const getQuestionQuestionId = useFetcher<Question>();

  return (questionId: string, signal?: AbortSignal) => {
    return getQuestionQuestionId({url: `/question/${questionId}`, method: 'get', signal});
  };
};

export const getGetQuestionQuestionIdQueryKey = (questionId: string) =>
  [`/question/${questionId}`] as const;

export const useGetQuestionQuestionIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuestionQuestionIdQueryKey(questionId);

  const getQuestionQuestionId = useGetQuestionQuestionIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>
  > = ({signal}) => getQuestionQuestionId(questionId, signal);

  return {queryKey, queryFn, enabled: !!questionId, ...queryOptions};
};

export type GetQuestionQuestionIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>
>;
export type GetQuestionQuestionIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetQuestionQuestionId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetQuestionQuestionIdQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetQuestionQuestionIdOptionsHook = () => {
  const getQuestionQuestionIdOptions = useFetcher<Options[]>();

  return (questionId: string, signal?: AbortSignal) => {
    return getQuestionQuestionIdOptions({
      url: `/question/${questionId}/options`,
      method: 'get',
      signal
    });
  };
};

export const getGetQuestionQuestionIdOptionsQueryKey = (questionId: string) =>
  [`/question/${questionId}/options`] as const;

export const useGetQuestionQuestionIdOptionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetQuestionQuestionIdOptionsQueryKey(questionId);

  const getQuestionQuestionIdOptions = useGetQuestionQuestionIdOptionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>
  > = ({signal}) => getQuestionQuestionIdOptions(questionId, signal);

  return {queryKey, queryFn, enabled: !!questionId, ...queryOptions};
};

export type GetQuestionQuestionIdOptionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>
>;
export type GetQuestionQuestionIdOptionsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetQuestionQuestionIdOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  questionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetQuestionQuestionIdOptionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetQuestionQuestionIdOptionsQueryOptions(questionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostQuestionQuestionIdOptionsHook = () => {
  const postQuestionQuestionIdOptions = useFetcher<Options[]>();

  return (questionId: string, options: NonReadonly<Options[]>) => {
    return postQuestionQuestionIdOptions({
      url: `/question/${questionId}/options`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: options
    });
  };
};

export const usePostQuestionQuestionIdOptionsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostQuestionQuestionIdOptionsHook>>>,
    TError,
    {questionId: string; data: NonReadonly<Options[]>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostQuestionQuestionIdOptionsHook>>>,
  TError,
  {questionId: string; data: NonReadonly<Options[]>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postQuestionQuestionIdOptions = usePostQuestionQuestionIdOptionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostQuestionQuestionIdOptionsHook>>>,
    {questionId: string; data: NonReadonly<Options[]>}
  > = props => {
    const {questionId, data} = props ?? {};

    return postQuestionQuestionIdOptions(questionId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostQuestionQuestionIdOptionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostQuestionQuestionIdOptionsHook>>>
>;
export type PostQuestionQuestionIdOptionsMutationBody = NonReadonly<Options[]>;
export type PostQuestionQuestionIdOptionsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostQuestionQuestionIdOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostQuestionQuestionIdOptionsHook>>>,
    TError,
    {questionId: string; data: NonReadonly<Options[]>},
    TContext
  >;
}) => {
  const mutationOptions = usePostQuestionQuestionIdOptionsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostRegisterHook = () => {
  const postRegister = useFetcher<UserRegister>();

  return (userRegister: UserRegister) => {
    return postRegister({
      url: `/register`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userRegister
    });
  };
};

export const usePostRegisterMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostRegisterHook>>>,
    TError,
    {data: UserRegister},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostRegisterHook>>>,
  TError,
  {data: UserRegister},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postRegister = usePostRegisterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostRegisterHook>>>,
    {data: UserRegister}
  > = props => {
    const {data} = props ?? {};

    return postRegister(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostRegisterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostRegisterHook>>>
>;
export type PostRegisterMutationBody = UserRegister;
export type PostRegisterMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostRegister = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostRegisterHook>>>,
    TError,
    {data: UserRegister},
    TContext
  >;
}) => {
  const mutationOptions = usePostRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetReportingHook = () => {
  const getReporting = useFetcher<Reporting[]>();

  return (signal?: AbortSignal) => {
    return getReporting({url: `/reporting`, method: 'get', signal});
  };
};

export const getGetReportingQueryKey = () => [`/reporting`] as const;

export const useGetReportingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportingQueryKey();

  const getReporting = useGetReportingHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>> = ({
    signal
  }) => getReporting(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetReportingQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>
>;
export type GetReportingQueryError = ErrorType<DefaultErrorResponse>;

export const useGetReporting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetReportingHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetReportingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetSectorHook = () => {
  const getSector = useFetcher<Sector[]>();

  return (signal?: AbortSignal) => {
    return getSector({url: `/sector`, method: 'get', signal});
  };
};

export const getGetSectorQueryKey = () => [`/sector`] as const;

export const useGetSectorQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSectorQueryKey();

  const getSector = useGetSectorHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>> = ({
    signal
  }) => getSector(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetSectorQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>
>;
export type GetSectorQueryError = ErrorType<DefaultErrorResponse>;

export const useGetSector = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSectorHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetSectorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetSectorSectorIdHook = () => {
  const getSectorSectorId = useFetcher<Sector>();

  return (sectorId: string, signal?: AbortSignal) => {
    return getSectorSectorId({url: `/sector/${sectorId}`, method: 'get', signal});
  };
};

export const getGetSectorSectorIdQueryKey = (sectorId: string) => [`/sector/${sectorId}`] as const;

export const useGetSectorSectorIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  sectorId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSectorSectorIdQueryKey(sectorId);

  const getSectorSectorId = useGetSectorSectorIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>
  > = ({signal}) => getSectorSectorId(sectorId, signal);

  return {queryKey, queryFn, enabled: !!sectorId, ...queryOptions};
};

export type GetSectorSectorIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>
>;
export type GetSectorSectorIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetSectorSectorId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  sectorId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetSectorSectorIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetSectorSectorIdQueryOptions(sectorId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetTierHook = () => {
  const getTier = useFetcher<Tier[]>();

  return (signal?: AbortSignal) => {
    return getTier({url: `/tier`, method: 'get', signal});
  };
};

export const getGetTierQueryKey = () => [`/tier`] as const;

export const useGetTierQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTierQueryKey();

  const getTier = useGetTierHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>> = ({
    signal
  }) => getTier(signal);

  return {queryKey, queryFn, ...queryOptions};
};

export type GetTierQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>
>;
export type GetTierQueryError = ErrorType<DefaultErrorResponse>;

export const useGetTier = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTierHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetTierQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetTierTierIdCourseHook = () => {
  const getTierTierIdCourse = useFetcher<Course[]>();

  return (tierId: string, signal?: AbortSignal) => {
    return getTierTierIdCourse({url: `/tier/${tierId}/course`, method: 'get', signal});
  };
};

export const getGetTierTierIdCourseQueryKey = (tierId: string) =>
  [`/tier/${tierId}/course`] as const;

export const useGetTierTierIdCourseQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  tierId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTierTierIdCourseQueryKey(tierId);

  const getTierTierIdCourse = useGetTierTierIdCourseHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>
  > = ({signal}) => getTierTierIdCourse(tierId, signal);

  return {queryKey, queryFn, enabled: !!tierId, ...queryOptions};
};

export type GetTierTierIdCourseQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>
>;
export type GetTierTierIdCourseQueryError = ErrorType<DefaultErrorResponse>;

export const useGetTierTierIdCourse = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  tierId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTierTierIdCourseHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetTierTierIdCourseQueryOptions(tierId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostTierTierIdCourseHook = () => {
  const postTierTierIdCourse = useFetcher<Course>();

  return (tierId: string, course: NonReadonly<Course>) => {
    return postTierTierIdCourse({
      url: `/tier/${tierId}/course`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: course
    });
  };
};

export const usePostTierTierIdCourseMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostTierTierIdCourseHook>>>,
    TError,
    {tierId: string; data: NonReadonly<Course>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostTierTierIdCourseHook>>>,
  TError,
  {tierId: string; data: NonReadonly<Course>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postTierTierIdCourse = usePostTierTierIdCourseHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostTierTierIdCourseHook>>>,
    {tierId: string; data: NonReadonly<Course>}
  > = props => {
    const {tierId, data} = props ?? {};

    return postTierTierIdCourse(tierId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostTierTierIdCourseMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostTierTierIdCourseHook>>>
>;
export type PostTierTierIdCourseMutationBody = NonReadonly<Course>;
export type PostTierTierIdCourseMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostTierTierIdCourse = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostTierTierIdCourseHook>>>,
    TError,
    {tierId: string; data: NonReadonly<Course>},
    TContext
  >;
}) => {
  const mutationOptions = usePostTierTierIdCourseMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetUserUserIdCourseCourseIdHook = () => {
  const getUserUserIdCourseCourseId = useFetcher<UserCourseDetails>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseId({
      url: `/user/${userId}/course/${courseId}`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdQueryKey = (userId: string, courseId: string) =>
  [`/user/${userId}/course/${courseId}`] as const;

export const useGetUserUserIdCourseCourseIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdQueryKey(userId, courseId);

  const getUserUserIdCourseCourseId = useGetUserUserIdCourseCourseIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>
  > = ({signal}) => getUserUserIdCourseCourseId(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>
>;
export type GetUserUserIdCourseCourseIdQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdQueryOptions(userId, courseId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostUserUserIdCourseCourseIdHook = () => {
  const postUserUserIdCourseCourseId = useFetcher<UserCourseDetails>();

  return (userId: string, courseId: string) => {
    return postUserUserIdCourseCourseId({
      url: `/user/${userId}/course/${courseId}`,
      method: 'post'
    });
  };
};

export const usePostUserUserIdCourseCourseIdMutationOptions = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdCourseCourseIdHook>>>,
    TError,
    {userId: string; courseId: string},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdCourseCourseIdHook>>>,
  TError,
  {userId: string; courseId: string},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postUserUserIdCourseCourseId = usePostUserUserIdCourseCourseIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdCourseCourseIdHook>>>,
    {userId: string; courseId: string}
  > = props => {
    const {userId, courseId} = props ?? {};

    return postUserUserIdCourseCourseId(userId, courseId);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostUserUserIdCourseCourseIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdCourseCourseIdHook>>>
>;

export type PostUserUserIdCourseCourseIdMutationError = ErrorType<DefaultErrorResponse>;

export const usePostUserUserIdCourseCourseId = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdCourseCourseIdHook>>>,
    TError,
    {userId: string; courseId: string},
    TContext
  >;
}) => {
  const mutationOptions = usePostUserUserIdCourseCourseIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetUserUserIdCourseCourseIdCertificateHook = () => {
  const getUserUserIdCourseCourseIdCertificate = useFetcher<unknown>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseIdCertificate({
      url: `/user/${userId}/course/${courseId}/certificate`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdCertificateQueryKey = (
  userId: string,
  courseId: string
) => [`/user/${userId}/course/${courseId}/certificate`] as const;

export const useGetUserUserIdCourseCourseIdCertificateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdCertificateQueryKey(userId, courseId);

  const getUserUserIdCourseCourseIdCertificate = useGetUserUserIdCourseCourseIdCertificateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>
  > = ({signal}) => getUserUserIdCourseCourseIdCertificate(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdCertificateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>
>;
export type GetUserUserIdCourseCourseIdCertificateQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseIdCertificate = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdCertificateQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdCourseCourseIdCurrentStepHook = () => {
  const getUserUserIdCourseCourseIdCurrentStep = useFetcher<ActiveLessonMaterial>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseIdCurrentStep({
      url: `/user/${userId}/course/${courseId}/current/step`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdCurrentStepQueryKey = (
  userId: string,
  courseId: string
) => [`/user/${userId}/course/${courseId}/current/step`] as const;

export const useGetUserUserIdCourseCourseIdCurrentStepQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdCurrentStepQueryKey(userId, courseId);

  const getUserUserIdCourseCourseIdCurrentStep = useGetUserUserIdCourseCourseIdCurrentStepHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>
  > = ({signal}) => getUserUserIdCourseCourseIdCurrentStep(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdCurrentStepQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>
>;
export type GetUserUserIdCourseCourseIdCurrentStepQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseIdCurrentStep = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdCurrentStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdCurrentStepQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdCourseCourseIdNextLessonHook = () => {
  const getUserUserIdCourseCourseIdNextLesson = useFetcher<ActiveLessonMaterial>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseIdNextLesson({
      url: `/user/${userId}/course/${courseId}/next/lesson`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdNextLessonQueryKey = (
  userId: string,
  courseId: string
) => [`/user/${userId}/course/${courseId}/next/lesson`] as const;

export const useGetUserUserIdCourseCourseIdNextLessonQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdNextLessonQueryKey(userId, courseId);

  const getUserUserIdCourseCourseIdNextLesson = useGetUserUserIdCourseCourseIdNextLessonHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>
  > = ({signal}) => getUserUserIdCourseCourseIdNextLesson(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdNextLessonQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>
>;
export type GetUserUserIdCourseCourseIdNextLessonQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseIdNextLesson = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextLessonHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdNextLessonQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdCourseCourseIdNextStepHook = () => {
  const getUserUserIdCourseCourseIdNextStep = useFetcher<ActiveLessonMaterial>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseIdNextStep({
      url: `/user/${userId}/course/${courseId}/next/step`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdNextStepQueryKey = (userId: string, courseId: string) =>
  [`/user/${userId}/course/${courseId}/next/step`] as const;

export const useGetUserUserIdCourseCourseIdNextStepQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdNextStepQueryKey(userId, courseId);

  const getUserUserIdCourseCourseIdNextStep = useGetUserUserIdCourseCourseIdNextStepHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>
  > = ({signal}) => getUserUserIdCourseCourseIdNextStep(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdNextStepQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>
>;
export type GetUserUserIdCourseCourseIdNextStepQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseIdNextStep = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdNextStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdNextStepQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdCourseCourseIdPreviousStepHook = () => {
  const getUserUserIdCourseCourseIdPreviousStep = useFetcher<ActiveLessonMaterial>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseCourseIdPreviousStep({
      url: `/user/${userId}/course/${courseId}/previous/step`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseCourseIdPreviousStepQueryKey = (
  userId: string,
  courseId: string
) => [`/user/${userId}/course/${courseId}/previous/step`] as const;

export const useGetUserUserIdCourseCourseIdPreviousStepQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdCourseCourseIdPreviousStepQueryKey(userId, courseId);

  const getUserUserIdCourseCourseIdPreviousStep = useGetUserUserIdCourseCourseIdPreviousStepHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>
  > = ({signal}) => getUserUserIdCourseCourseIdPreviousStep(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetUserUserIdCourseCourseIdPreviousStepQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>
>;
export type GetUserUserIdCourseCourseIdPreviousStepQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseCourseIdPreviousStep = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseCourseIdPreviousStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseCourseIdPreviousStepQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdCourseDetailsHook = () => {
  const getUserUserIdCourseDetails = useFetcher<UserCourseDetails[]>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserUserIdCourseDetails({
      url: `/user/${userId}/course_details`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdCourseDetailsQueryKey = (userId: string) =>
  [`/user/${userId}/course_details`] as const;

export const useGetUserUserIdCourseDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserUserIdCourseDetailsQueryKey(userId);

  const getUserUserIdCourseDetails = useGetUserUserIdCourseDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>
  > = ({signal}) => getUserUserIdCourseDetails(userId, signal);

  return {queryKey, queryFn, enabled: !!userId, ...queryOptions};
};

export type GetUserUserIdCourseDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>
>;
export type GetUserUserIdCourseDetailsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdCourseDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdCourseDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdCourseDetailsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdExamExamIdExamDetailsHook = () => {
  const getUserUserIdExamExamIdExamDetails = useFetcher<ExamDetails>();

  return (userId: string, examId: string, signal?: AbortSignal) => {
    return getUserUserIdExamExamIdExamDetails({
      url: `/user/${userId}/exam/${examId}/exam_details`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdExamExamIdExamDetailsQueryKey = (userId: string, examId: string) =>
  [`/user/${userId}/exam/${examId}/exam_details`] as const;

export const useGetUserUserIdExamExamIdExamDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdExamExamIdExamDetailsQueryKey(userId, examId);

  const getUserUserIdExamExamIdExamDetails = useGetUserUserIdExamExamIdExamDetailsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>
  > = ({signal}) => getUserUserIdExamExamIdExamDetails(userId, examId, signal);

  return {queryKey, queryFn, enabled: !!(userId && examId), ...queryOptions};
};

export type GetUserUserIdExamExamIdExamDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>
>;
export type GetUserUserIdExamExamIdExamDetailsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdExamExamIdExamDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  examId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdExamExamIdExamDetailsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdExamExamIdExamDetailsQueryOptions(userId, examId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostUserUserIdExamExamIdExamDetailsHook = () => {
  const postUserUserIdExamExamIdExamDetails = useFetcher<ExamDetails>();

  return (userId: string, examId: string, examDetails: NonReadonly<ExamDetails>) => {
    return postUserUserIdExamExamIdExamDetails({
      url: `/user/${userId}/exam/${examId}/exam_details`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: examDetails
    });
  };
};

export const usePostUserUserIdExamExamIdExamDetailsMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdExamExamIdExamDetailsHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdExamExamIdExamDetailsHook>>>,
  TError,
  {userId: string; examId: string; data: NonReadonly<ExamDetails>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postUserUserIdExamExamIdExamDetails = usePostUserUserIdExamExamIdExamDetailsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdExamExamIdExamDetailsHook>>>,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>}
  > = props => {
    const {userId, examId, data} = props ?? {};

    return postUserUserIdExamExamIdExamDetails(userId, examId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostUserUserIdExamExamIdExamDetailsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdExamExamIdExamDetailsHook>>>
>;
export type PostUserUserIdExamExamIdExamDetailsMutationBody = NonReadonly<ExamDetails>;
export type PostUserUserIdExamExamIdExamDetailsMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostUserUserIdExamExamIdExamDetails = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdExamExamIdExamDetailsHook>>>,
    TError,
    {userId: string; examId: string; data: NonReadonly<ExamDetails>},
    TContext
  >;
}) => {
  const mutationOptions = usePostUserUserIdExamExamIdExamDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetUserUserIdLessonLessonIdLatestStepHook = () => {
  const getUserUserIdLessonLessonIdLatestStep = useFetcher<ActiveLessonMaterial>();

  return (userId: string, lessonId: string, signal?: AbortSignal) => {
    return getUserUserIdLessonLessonIdLatestStep({
      url: `/user/${userId}/lesson/${lessonId}/latest/step`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdLessonLessonIdLatestStepQueryKey = (
  userId: string,
  lessonId: string
) => [`/user/${userId}/lesson/${lessonId}/latest/step`] as const;

export const useGetUserUserIdLessonLessonIdLatestStepQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserUserIdLessonLessonIdLatestStepQueryKey(userId, lessonId);

  const getUserUserIdLessonLessonIdLatestStep = useGetUserUserIdLessonLessonIdLatestStepHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>
  > = ({signal}) => getUserUserIdLessonLessonIdLatestStep(userId, lessonId, signal);

  return {queryKey, queryFn, enabled: !!(userId && lessonId), ...queryOptions};
};

export type GetUserUserIdLessonLessonIdLatestStepQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>
>;
export type GetUserUserIdLessonLessonIdLatestStepQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdLessonLessonIdLatestStep = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLatestStepHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdLessonLessonIdLatestStepQueryOptions(
    userId,
    lessonId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdLessonLessonIdLessonMaterialsHook = () => {
  const getUserUserIdLessonLessonIdLessonMaterials = useFetcher<ActiveLessonMaterial[]>();

  return (userId: string, lessonId: string, signal?: AbortSignal) => {
    return getUserUserIdLessonLessonIdLessonMaterials({
      url: `/user/${userId}/lesson/${lessonId}/lesson-materials`,
      method: 'get',
      signal
    });
  };
};

export const getGetUserUserIdLessonLessonIdLessonMaterialsQueryKey = (
  userId: string,
  lessonId: string
) => [`/user/${userId}/lesson/${lessonId}/lesson-materials`] as const;

export const useGetUserUserIdLessonLessonIdLessonMaterialsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetUserUserIdLessonLessonIdLessonMaterialsQueryKey(userId, lessonId);

  const getUserUserIdLessonLessonIdLessonMaterials =
    useGetUserUserIdLessonLessonIdLessonMaterialsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>
  > = ({signal}) => getUserUserIdLessonLessonIdLessonMaterials(userId, lessonId, signal);

  return {queryKey, queryFn, enabled: !!(userId && lessonId), ...queryOptions};
};

export type GetUserUserIdLessonLessonIdLessonMaterialsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>
>;
export type GetUserUserIdLessonLessonIdLessonMaterialsQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdLessonLessonIdLessonMaterials = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  lessonId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdLessonLessonIdLessonMaterialsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdLessonLessonIdLessonMaterialsQueryOptions(
    userId,
    lessonId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetUserUserIdTiersHook = () => {
  const getUserUserIdTiers = useFetcher<UserTier[]>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserUserIdTiers({url: `/user/${userId}/tiers`, method: 'get', signal});
  };
};

export const getGetUserUserIdTiersQueryKey = (userId: string) => [`/user/${userId}/tiers`] as const;

export const useGetUserUserIdTiersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserUserIdTiersQueryKey(userId);

  const getUserUserIdTiers = useGetUserUserIdTiersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>
  > = ({signal}) => getUserUserIdTiers(userId, signal);

  return {queryKey, queryFn, enabled: !!userId, ...queryOptions};
};

export type GetUserUserIdTiersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>
>;
export type GetUserUserIdTiersQueryError = ErrorType<DefaultErrorResponse>;

export const useGetUserUserIdTiers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserUserIdTiersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetUserUserIdTiersQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostUserUserIdTiersHook = () => {
  const postUserUserIdTiers = useFetcher<UserTier>();

  return (userId: string) => {
    return postUserUserIdTiers({url: `/user/${userId}/tiers`, method: 'post'});
  };
};

export const usePostUserUserIdTiersMutationOptions = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdTiersHook>>>,
    TError,
    {userId: string},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdTiersHook>>>,
  TError,
  {userId: string},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postUserUserIdTiers = usePostUserUserIdTiersHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdTiersHook>>>,
    {userId: string}
  > = props => {
    const {userId} = props ?? {};

    return postUserUserIdTiers(userId);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostUserUserIdTiersMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUserUserIdTiersHook>>>
>;

export type PostUserUserIdTiersMutationError = ErrorType<DefaultErrorResponse>;

export const usePostUserUserIdTiers = <
  TError = ErrorType<DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserUserIdTiersHook>>>,
    TError,
    {userId: string},
    TContext
  >;
}) => {
  const mutationOptions = usePostUserUserIdTiersMutationOptions(options);

  return useMutation(mutationOptions);
};

export const usePostUserAnswersExamIdExamHook = () => {
  const postUserAnswersExamIdExam = useFetcher<UserAnswer[]>();

  return (examId: string, userAnswer: NonReadonly<UserAnswer[]>) => {
    return postUserAnswersExamIdExam({
      url: `/user_answers/${examId}/exam`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userAnswer
    });
  };
};

export const usePostUserAnswersExamIdExamMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserAnswersExamIdExamHook>>>,
    TError,
    {examId: string; data: NonReadonly<UserAnswer[]>},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostUserAnswersExamIdExamHook>>>,
  TError,
  {examId: string; data: NonReadonly<UserAnswer[]>},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postUserAnswersExamIdExam = usePostUserAnswersExamIdExamHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostUserAnswersExamIdExamHook>>>,
    {examId: string; data: NonReadonly<UserAnswer[]>}
  > = props => {
    const {examId, data} = props ?? {};

    return postUserAnswersExamIdExam(examId, data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostUserAnswersExamIdExamMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostUserAnswersExamIdExamHook>>>
>;
export type PostUserAnswersExamIdExamMutationBody = NonReadonly<UserAnswer[]>;
export type PostUserAnswersExamIdExamMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostUserAnswersExamIdExam = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostUserAnswersExamIdExamHook>>>,
    TError,
    {examId: string; data: NonReadonly<UserAnswer[]>},
    TContext
  >;
}) => {
  const mutationOptions = usePostUserAnswersExamIdExamMutationOptions(options);

  return useMutation(mutationOptions);
};

export const useGetV2GotbotUserUserIdCourseCourseIdCertificateHook = () => {
  const getV2GotbotUserUserIdCourseCourseIdCertificate = useFetcher<unknown>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getV2GotbotUserUserIdCourseCourseIdCertificate({
      url: `/v2/gotbot/user/${userId}/course/${courseId}/certificate`,
      method: 'get',
      signal
    });
  };
};

export const getGetV2GotbotUserUserIdCourseCourseIdCertificateQueryKey = (
  userId: string,
  courseId: string
) => [`/v2/gotbot/user/${userId}/course/${courseId}/certificate`] as const;

export const useGetV2GotbotUserUserIdCourseCourseIdCertificateQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetV2GotbotUserUserIdCourseCourseIdCertificateQueryKey(userId, courseId);

  const getV2GotbotUserUserIdCourseCourseIdCertificate =
    useGetV2GotbotUserUserIdCourseCourseIdCertificateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>>
  > = ({signal}) => getV2GotbotUserUserIdCourseCourseIdCertificate(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetV2GotbotUserUserIdCourseCourseIdCertificateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>>
>;
export type GetV2GotbotUserUserIdCourseCourseIdCertificateQueryError =
  ErrorType<DefaultErrorResponse>;

export const useGetV2GotbotUserUserIdCourseCourseIdCertificate = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>
  >,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetV2GotbotUserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetV2GotbotUserUserIdCourseCourseIdCertificateQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useGetV2UserUserIdCourseCourseIdCertificateHook = () => {
  const getV2UserUserIdCourseCourseIdCertificate = useFetcher<unknown>();

  return (userId: string, courseId: string, signal?: AbortSignal) => {
    return getV2UserUserIdCourseCourseIdCertificate({
      url: `/v2/user/${userId}/course/${courseId}/certificate`,
      method: 'get',
      signal
    });
  };
};

export const getGetV2UserUserIdCourseCourseIdCertificateQueryKey = (
  userId: string,
  courseId: string
) => [`/v2/user/${userId}/course/${courseId}/certificate`] as const;

export const useGetV2UserUserIdCourseCourseIdCertificateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>,
  TError,
  TData
> & {queryKey: QueryKey} => {
  const {query: queryOptions} = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetV2UserUserIdCourseCourseIdCertificateQueryKey(userId, courseId);

  const getV2UserUserIdCourseCourseIdCertificate =
    useGetV2UserUserIdCourseCourseIdCertificateHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>
  > = ({signal}) => getV2UserUserIdCourseCourseIdCertificate(userId, courseId, signal);

  return {queryKey, queryFn, enabled: !!(userId && courseId), ...queryOptions};
};

export type GetV2UserUserIdCourseCourseIdCertificateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>
>;
export type GetV2UserUserIdCourseCourseIdCertificateQueryError = ErrorType<DefaultErrorResponse>;

export const useGetV2UserUserIdCourseCourseIdCertificate = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>,
  TError = ErrorType<DefaultErrorResponse>
>(
  userId: string,
  courseId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetV2UserUserIdCourseCourseIdCertificateHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
  const queryOptions = useGetV2UserUserIdCourseCourseIdCertificateQueryOptions(
    userId,
    courseId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePostVerifyUserHook = () => {
  const postVerifyUser = useFetcher<UserVerification>();

  return (userVerification: UserVerification) => {
    return postVerifyUser({
      url: `/verify-user`,
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userVerification
    });
  };
};

export const usePostVerifyUserMutationOptions = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostVerifyUserHook>>>,
    TError,
    {data: UserVerification},
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostVerifyUserHook>>>,
  TError,
  {data: UserVerification},
  TContext
> => {
  const {mutation: mutationOptions} = options ?? {};

  const postVerifyUser = usePostVerifyUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostVerifyUserHook>>>,
    {data: UserVerification}
  > = props => {
    const {data} = props ?? {};

    return postVerifyUser(data);
  };

  return {mutationFn, ...mutationOptions};
};

export type PostVerifyUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostVerifyUserHook>>>
>;
export type PostVerifyUserMutationBody = UserVerification;
export type PostVerifyUserMutationError = ErrorType<
  UnprocessableEntityResponse | DefaultErrorResponse
>;

export const usePostVerifyUser = <
  TError = ErrorType<UnprocessableEntityResponse | DefaultErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostVerifyUserHook>>>,
    TError,
    {data: UserVerification},
    TContext
  >;
}) => {
  const mutationOptions = usePostVerifyUserMutationOptions(options);

  return useMutation(mutationOptions);
};
