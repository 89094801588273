import ConnectForm from 'src/modules/common/components/ConnectFrom';
import {RegistrationFrom, useRegister} from 'src/lib/context/register-context';
import {Label} from 'src/modules/common/components/Label';
import NativeSelect from 'src/modules/common/components/NativeSelect';
import RadioGroup from 'src/modules/common/components/Radio';
import {Button} from 'src/modules/common/components/Button';

const BusinessDetails = () => {
  const {
    handleSubmit,
    editAdditionalBusinessDetailsForm: {toggle: setAdditionalEdit},
    editBusinessDetailsForm: {state: isEdit, toggle: setEdit}
  } = useRegister();

  const switchPanel = () => {
    setEdit();
    setAdditionalEdit();
  };

  return (
    <div>
      {isEdit && (
        <>
          {/* <div className='w-full flex flex-col items-center'>
            <span className='text-3xl font-semibold mb-6 font-effra-bold text-[#22436D] uppercase'>
              Register
            </span>
            <span className='text-center text-base-regular text-gray-700 mb-8'>
              To start your journey to success
            </span>
          </div> */}
          <ConnectForm<RegistrationFrom>>
            {({register, formState: {errors, touchedFields}}) => (
              <div className='grid grid-cols-1 gap-y-10'>
                <Label label={'How long has your business been in operation'}>
                  <NativeSelect
                    {...register('business_details.age', {
                      required: 'Business age is required'
                    })}
                    errors={errors}
                    touched={touchedFields}
                  >
                    <option value={'<1year'}>Less than one year</option>
                    <option value={'1-2years'}>1 - 2 Years</option>
                    <option value={'>3+ years'}>+3 Years</option>
                  </NativeSelect>
                </Label>
                <div className='flex flex-col gap-6'>
                  <Label label={'Do you have a business name and entity?'}>
                    <RadioGroup
                      {...register('business_details.has_entity_name', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                  <Label label={'Do you have a business plan?'}>
                    <RadioGroup
                      {...register('business_details.has_business_plan', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                  <Label label={'Do you have a business budget in place?'}>
                    <RadioGroup
                      {...register('business_details.has_business_budget', {
                        required: 'field is required'
                      })}
                      touched={touchedFields}
                      errors={errors}
                    />
                  </Label>
                </div>

                <Button className='mt-6 bg-primary-active' onClick={handleSubmit(switchPanel)}>
                  Next
                </Button>
              </div>
            )}
          </ConnectForm>
        </>
      )}
    </div>
  );
};

export default BusinessDetails;
