import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAccount} from 'src/lib/context/account-context';
import Login from '../components/Login';

const LoginTemplate = () => {
  const {user, retrievingUser} = useAccount();

  const navigate = useNavigate();

  useEffect(() => {
    if (!retrievingUser && user) {
      navigate('/dashboard');
    }
  }, [navigate, retrievingUser, user]);

  return (
    <div className='w-screen h-screen grid place-items-center bg-[#FDF9F2]'>
      <Login />
    </div>
  );
};

export default LoginTemplate;
