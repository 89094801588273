import ConnectForm from 'src/modules/common/components/ConnectFrom';
import Input from 'src/modules/common/components/Input';
import {RegistrationFrom, useRegister} from 'src/lib/context/register-context';
import NativeSelect from 'src/modules/common/components/NativeSelect';
import {useGetSector} from 'src/api';
import {Label} from 'src/modules/common/components/Label';
import CountrySelect from './CountrySelect';
import {Button} from 'src/modules/common/components/Button';

const AdditionalUserDetails = () => {
  const {
    handleSubmit,
    editBusinessDetailsForm: {toggle: setBusinessEdit},
    editAdditionalUserDetailsForm: {state: isEdit, toggle: setEdit}
  } = useRegister();

  const {data: sectorData} = useGetSector();

  const switchPanel = () => {
    setEdit();
    setBusinessEdit();
  };

  return (
    <div>
      {isEdit && (
        <>
          <ConnectForm<RegistrationFrom>>
            {({register, formState: {errors, touchedFields}}) => (
              <div className='grid grid-cols-1 space-y-5'>
                <Label label={'Language'}>
                  <NativeSelect
                    {...register('additional_personal_info.language', {
                      required: 'Language is required'
                    })}
                    errors={errors}
                    touched={touchedFields}
                    className='text-sm font-medium text-[#475467]'
                  >
                    <option value={'english'}>English</option>
                    <option value={'french'}>French</option>
                  </NativeSelect>
                </Label>
                <Label label={'Gender'}>
                  <NativeSelect
                    {...register('additional_personal_info.gender', {
                      required: 'Gender is required'
                    })}
                    errors={errors}
                    touched={touchedFields}
                    className='text-sm font-medium text-[#475467]'
                  >
                    <option value={'male'}>Male</option>
                    <option value={'female'}>Female</option>
                    <option value={'other'}>Other</option>
                  </NativeSelect>
                </Label>

                <Input
                  label='Age'
                  {...register('additional_personal_info.age', {
                    required: 'Age is required'
                  })}
                  autoComplete='age'
                  errors={errors}
                  touched={touchedFields}
                  type='number'
                  className='text-sm font-medium text-[#475467]'
                />
                <Label label={'Sector'}>
                  <NativeSelect
                    {...register('additional_personal_info.sector', {
                      required: 'Sector is required'
                    })}
                    errors={errors}
                    touched={touchedFields}
                    className='text-sm font-medium text-[#475467]'
                  >
                    {sectorData?.map(s => (
                      <option key={`key-sector${s.id}`} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </NativeSelect>
                </Label>
                <Label label={'Country'}>
                  <CountrySelect
                    {...register('additional_personal_info.country_iso_code', {
                      required: 'Country is required'
                    })}
                    autoComplete='country'
                    errors={errors}
                    touched={touchedFields}
                    className='text-sm font-medium text-[#475467]'
                  />
                </Label>

                <Button className='mt-6 bg-primary-active' onClick={handleSubmit(switchPanel)}>
                  Next
                </Button>
              </div>
            )}
          </ConnectForm>
        </>
      )}
    </div>
  );
};

export default AdditionalUserDetails;
