import clsx from 'clsx';
import React, {useEffect, useImperativeHandle, useState} from 'react';
import Eye from '../icons/eye';
import EyeOff from '../icons/eye-off';
import {get} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
  name: string;
  className?: string;
  variant?: 'block' | 'inline';
  inputWrapperClassName?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      name,
      label,
      errors,
      touched,
      required,
      variant,
      inputWrapperClassName,
      className,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState(type);

    useEffect(() => {
      if (type === 'password' && showPassword) {
        setInputType('text');
      }

      if (type === 'password' && !showPassword) {
        setInputType('password');
      }
    }, [type, showPassword]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRef.current!);

    const hasError = get(errors, name) && get(touched, name);

    return (
      <label
        htmlFor={name}
        onClick={() => inputRef.current?.focus()}
        className={clsx(className || 'w-full', {
          '!text-rose-500': hasError
        })}
      >
        {label}
        {required && <span className='text-rose-500'>*</span>}
        <div
          className={clsx(
            'relative  box-content rounded-md focus-within:ring-primary focus-within:ring overflow-hidden flex flex-row items-center h-11',
            errors ? 'border-red' : 'border-coolGray-300',
            'border mt-1',
            inputWrapperClassName
          )}
        >
          <input
            type={inputType}
            name={name}
            className={clsx(
              '!border-none inset-0 w-full h-full px-4 m-0 outline-none focus:outline-none text-body',
              variant === 'inline' && ''
            )}
            {...props}
            ref={inputRef}
            placeholder={props.placeholder ?? label}
          />
          {type === 'password' && (
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
              className='text-gray-400 px-4 focus:outline-none transition-all duration-150 outline-none focus:text-gray-700 absolute right-0 top-3'
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          )}
        </div>
        {hasError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({message}) => {
              return (
                <div className='pt-1 pl-2 text-rose-500 text-xsmall-regular'>
                  <span>{message}</span>
                </div>
              );
            }}
          />
        )}
      </label>
    );
  }
);

Input.displayName = 'Input';

export default Input;
