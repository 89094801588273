import {useNavigate} from 'react-router-dom';
import AdditionalBusinessDetails from '../components/AdditionalBusinessDetails';
import AdditionalUserDetails from '../components/AdditionalUserDetails';
import BusinessDetails from '../components/BusinessDetails';
import UserDetails from '../components/UserDetails';
import RegistrationComplete from '../components/RegistrationComplete';

const RegisterForm = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='w-full grid grid-cols-1 w-90' style={{width: '360px'}}>
        <div>
          <UserDetails />
        </div>

        <div>
          <AdditionalUserDetails />
        </div>

        <div>
          <BusinessDetails />
        </div>
        <div>
          <AdditionalBusinessDetails />
        </div>
        <div>
          <RegistrationComplete />
        </div>
      </div>
      <div className='flex flex-col justify-center mt-4'>
        <span className='text-center text-gray-700 text-small-regular'>
          Already have an account?{' '}
          <button onClick={() => navigate('/login')} className='font-bold text-primary-active'>
            Log in
          </button>
          .
        </span>
      </div>
    </div>
  );
};

export default RegisterForm;
