import React, {createContext, useContext, useState} from 'react';
import {UserLanguage, UserOnboarding, UserRegisterGender} from 'src/api/models';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import useToggleState, {StateType} from '../hooks/use-toggle-state';
import {usePostOnboarding, usePostVerifyUser} from 'src/api';
import {getErrorMessage} from '../util/error-messages';

interface AdditionalPersonalDetails {
  language: string;
  gender: UserRegisterGender;
  age: number;
  sector: string;
  country_iso_code: string;
}

interface PersonalDetails {
  name: string;
  surname: string;
  usadf_id: string;
  email: string;
  phone: string;
}

interface BusinessDetails {
  age: string;
  has_entity_name: boolean;
  has_business_plan: boolean;
  has_business_budget: boolean;
}

interface AdditionalBusinessDetails {
  name: string;
  has_financial_goals: boolean;
  has_secured_financing: boolean;
  is_profitable: boolean;
  has_scailing_plan: boolean;
}

export interface RegistrationFrom {
  personal_info: PersonalDetails;
  additional_personal_info: AdditionalPersonalDetails;
  business_details: BusinessDetails;
  additional_business_details: AdditionalBusinessDetails;
}

interface RegisterProviderContext {
  editUserDetailsForm: StateType;
  editAdditionalUserDetailsForm: StateType;
  editBusinessDetailsForm: StateType;
  editAdditionalBusinessDetailsForm: StateType;
  verificationError: string | undefined;
  confirmationError: string | undefined;
  complete: boolean;
  saveRegister: () => void;
  verifyUser: () => void;
}

const RegisterContext = createContext<RegisterProviderContext | null>(null);

interface RegisterProviderProps {
  children?: React.ReactNode;
}

export const RegisterProvider = ({children}: RegisterProviderProps) => {
  const {mutate: userVerfication} = usePostVerifyUser();
  const {mutate: userConfirmation} = usePostOnboarding();
  const [verificationError, setVerificationError] = useState<string | undefined>();
  const [confirmationError, setConfirmationError] = useState<string | undefined>();
  const [complete, setComplete] = useState(false);
  const editUserDetailsForm = useToggleState(true);
  const editAdditionalUserDetailsForm = useToggleState();
  const editBusinessDetailsForm = useToggleState();
  const editAdditionalBusinessDetailsForm = useToggleState();

  const methods = useForm<RegistrationFrom>({
    reValidateMode: 'onChange'
  });

  const saveRegister = () => {
    const registerData = methods.getValues();
    const user: UserOnboarding = {
      user_details: {
        surname: registerData.personal_info.surname,
        email: registerData.personal_info.email,
        gender: registerData.additional_personal_info.gender,
        country_iso_code: registerData.additional_personal_info.country_iso_code,
        usadf_id: registerData.personal_info.usadf_id,
        phone: '',
        name: registerData.personal_info.name,
        language: registerData.additional_personal_info.language as UserLanguage,
        age: registerData.additional_personal_info.age
      },
      business_details: {
        sector_id: registerData.additional_personal_info.sector,
        has_scailing_plan: registerData.additional_business_details.has_scailing_plan,
        has_financial_goals: registerData.additional_business_details.has_financial_goals,
        name: registerData.additional_business_details.name ?? 'n/a',
        status: 'active',
        is_profitable: registerData.additional_business_details.is_profitable,
        has_business_plan: registerData.business_details.has_business_plan,
        has_business_budget: registerData.business_details.has_business_budget,
        age: registerData.business_details.age,
        has_secured_financing: registerData.additional_business_details.has_secured_financing
      }
    };
    userConfirmation(
      {data: user},
      {
        onSuccess: res => {
          localStorage.setItem('token', res.access_token ?? '');
          editAdditionalBusinessDetailsForm.close();
          setConfirmationError(undefined);
          setComplete(true);
        },
        onError: err => {
          const error = getErrorMessage(err);
          setConfirmationError(error);
        }
      }
    );
  };

  const verifyUser = () => {
    const userData = methods.getValues().personal_info;
    userData.phone = '';
    userVerfication(
      {data: userData},
      {
        onSuccess: () => {
          editUserDetailsForm.close();
          editAdditionalUserDetailsForm.open();
          setVerificationError(undefined);
        },
        onError: err => {
          const error = getErrorMessage(err);
          setVerificationError(error);
        }
      }
    );
  };

  return (
    <FormProvider {...methods}>
      <RegisterContext.Provider
        value={{
          editUserDetailsForm,
          editAdditionalUserDetailsForm,
          editBusinessDetailsForm,
          editAdditionalBusinessDetailsForm,
          verificationError,
          confirmationError,
          complete,
          saveRegister,
          verifyUser
        }}
      >
        {children}
      </RegisterContext.Provider>
    </FormProvider>
  );
};

export const useRegister = () => {
  const context = useContext(RegisterContext);
  const form = useFormContext<RegistrationFrom>();
  if (context === null) {
    throw new Error('useRegister must be used within a RegisterProvider');
  }

  return {...context, ...form};
};
