import clsx from 'clsx';
import {FC} from 'react';

export type LabelProps = {
  variant?: 'block' | 'inline';
  label: string | JSX.Element;
  required?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export const Label: FC<LabelProps> = ({
  children,
  className,
  label,
  required,
  variant = 'block'
}) => {
  return (
    <label className={clsx(className || 'w-full')}>
      {variant === 'block' && (
        <span className='text-sm font-medium text-[#475467] '>
          {label}
          {required && '*'}
        </span>
      )}
      <div className='relative box-content'>{children}</div>
    </label>
  );
};
