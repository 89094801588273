import React from 'react';

interface Step {
  title: string;
  description: string;
  onClick?: () => void;
}

interface StepWizardProps {
  steps: Step[];
  currentStep: number;
}

const StepWizard: React.FC<StepWizardProps> = ({steps, currentStep}) => {
  return (
    <div className='flex flex-col items-start text-white'>
      {steps.map((step, index) => (
        <>
          <div
            key={index}
            className='flex items-start pt-1'
            onClick={index < currentStep ? step.onClick : () => {}}
          >
            <div
              className={`w-10 h-10 rounded-full flex justify-center items-center  font-bold ${
                index <= currentStep ? 'bg-white' : 'bg-primary-active'
              }`}
            >
              {index < currentStep || currentStep === 4 ? (
                <div className='text-primary-active'>✓</div>
              ) : (
                <div
                  className={`w-2 h-2 rounded-full flex justify-center items-center bg-primary-active ${
                    index <= currentStep ? 'bg-primary-active' : 'bg-white'
                  }`}
                ></div>
              )}
            </div>
            <div className='ml-4'>
              <div>
                <span className='font-semibold'>{step.title}</span>
              </div>
              <div>
                <span className='text-sm font-normal text-[#D7DFED]'>{step.description}</span>
              </div>
            </div>
          </div>
          {index !== steps.length - 1 && (
            <div
              className={`ml-5  h-10 border  ${
                index < currentStep ? 'border-primary-active' : 'border-white'
              }`}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default StepWizard;
