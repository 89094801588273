import React, {createContext, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetCurrentUser} from 'src/api';
import {User} from 'src/api/models';

interface AccountProviderContext {
  user?: Omit<User, 'password'>;
  retrievingUser: boolean;
  checkSession: () => void;
  refetchuser: () => void;
  handleLogout: () => void;
}

const AccountContext = createContext<AccountProviderContext | null>(null);

interface AccountProviderProps {
  children?: React.ReactNode;
}

export const AccountProvider = ({children}: AccountProviderProps) => {
  const {data: user, isLoading: retrievingUser, refetch, remove} = useGetCurrentUser();

  const navigate = useNavigate();

  const checkSession = useCallback(() => {
    if (!user && !retrievingUser) {
      navigate('/login');
    }
  }, [user, retrievingUser, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    remove();
    navigate('/login');
  };

  return (
    <AccountContext.Provider
      value={{
        user,
        retrievingUser,
        checkSession,
        refetchuser: refetch,
        handleLogout
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error('useAccount must be used within a AccountProvider');
  }

  return context;
};
