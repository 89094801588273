import Axios, {AxiosError, AxiosRequestConfig} from 'axios';

// Use for production
// ! When the redirect happens in keycloak it adds it's own parameters to the url. The logic below strips out those parameters and makes the urls dynamic
export const baseUri = window.location.href.startsWith('https://usadf-staging')
  ? 'https://usadf-staging.injozitech.com/api'
  : 'https://lms.usadflearninghub.com/api';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: baseUri
});

export const useFetcher = <T>(): ((config: AxiosRequestConfig) => Promise<T>) => {
  const token = localStorage.getItem('token');

  return (config: AxiosRequestConfig) => {
    const source = Axios.CancelToken.source();
    const promise = AXIOS_INSTANCE({
      ...config,
      headers: {
        Authorization: `Bearer ${token}`
      },
      cancelToken: source.token
    }).then(({data}) => data);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => {
      source.cancel('Query was cancelled by React Query');
    };

    return promise;
  };
};

export default useFetcher;

export type ErrorType<Error> = AxiosError<Error>;
