import {RegisterProvider} from 'src/lib/context/register-context';
import RegisterTemplate from 'src/modules/account/templates/RegisterTemplate';
import RegisterLayout from 'src/modules/layout/RegisterLayout';

const RegisterPage = () => {
  return (
    <RegisterProvider>
      <RegisterLayout>
        <RegisterTemplate />
      </RegisterLayout>
    </RegisterProvider>
  );
};

export default RegisterPage;
