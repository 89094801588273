import {PropsWithChildren, useEffect, useState} from 'react';
import StepWizard from '../account/components/StepWizard';
import logo from '../../../src/assets/learning_hub_register_logo.png';
import {useRegister} from 'src/lib/context/register-context';
import {StateType} from 'src/lib/hooks/use-toggle-state';

const RegisterLayout = ({children}: PropsWithChildren) => {
  const {
    editUserDetailsForm,
    editAdditionalUserDetailsForm,
    editBusinessDetailsForm,
    editAdditionalBusinessDetailsForm
  } = useRegister();

  const date = new Date();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (editUserDetailsForm.state) {
      setCurrentStep(0);
    } else if (editAdditionalUserDetailsForm.state) {
      setCurrentStep(1);
    } else if (editBusinessDetailsForm.state) {
      setCurrentStep(2);
    } else if (editAdditionalBusinessDetailsForm.state) {
      setCurrentStep(3);
    } else {
      setCurrentStep(4);
    }
  }, [
    editUserDetailsForm,
    editAdditionalUserDetailsForm,
    editBusinessDetailsForm,
    editAdditionalBusinessDetailsForm
  ]);

  const switchState = (state: StateType) => {
    if (state === editUserDetailsForm) {
      editUserDetailsForm.open();
      editAdditionalUserDetailsForm.close();
      editBusinessDetailsForm.close();
      editAdditionalBusinessDetailsForm.close();
    } else if (state === editAdditionalUserDetailsForm) {
      editUserDetailsForm.close();
      editAdditionalUserDetailsForm.open();
      editBusinessDetailsForm.close();
      editAdditionalBusinessDetailsForm.close();
    } else if (state === editBusinessDetailsForm) {
      editUserDetailsForm.close();
      editAdditionalUserDetailsForm.close();
      editBusinessDetailsForm.open();
      editAdditionalBusinessDetailsForm.close();
    } else if (state === editAdditionalBusinessDetailsForm) {
      editUserDetailsForm.close();
      editAdditionalUserDetailsForm.close();
      editBusinessDetailsForm.close();
      editAdditionalBusinessDetailsForm.open();
    } else {
      editUserDetailsForm.close();
      editAdditionalUserDetailsForm.close();
      editBusinessDetailsForm.close();
      editAdditionalBusinessDetailsForm.close();
    }
  };

  const steps = [
    {
      title: 'Your details',
      description: 'Some details about you',
      onClick: () => switchState(editUserDetailsForm)
    },
    {
      title: 'Your details continued',
      description: 'Some more details about you',
      onClick: () => switchState(editAdditionalUserDetailsForm)
    },
    {
      title: 'Your business details',
      description: 'Some details about your business',
      onClick: () => switchState(editBusinessDetailsForm)
    },
    {
      title: 'Your business details continued',
      description: 'Some more details about your business',
      onClick: () => switchState(editAdditionalBusinessDetailsForm)
    },
    {
      title: 'Complete',
      description: 'Let’s go!'
    }
  ];

  return (
    <div className='grid w-full min-h-screen bg-[#FDF9F2] grid-cols-1 lg:grid-cols-[min-content_1fr]'>
      <div
        className='hidden lg:block h-full p-10  bg-primary shadow-2xl rounded-r-3xl'
        style={{width: '464px'}}
      >
        <div className='flex flex-col justify-between h-32 '>
          <div className='flex items-center justify-center'>
            <img src={logo} alt='Logo' className='block w-[226px] h-[63px] object-cover' />
          </div>
        </div>
        <StepWizard steps={steps} currentStep={currentStep} />
        <div className='mt-[150px] flex items-center justify-center'>
          <span className='text-[#FFF] text-small-regular'>
            &copy; {date.getFullYear()} USADF. All rights reserved.
          </span>
        </div>
      </div>
      <main className='relative'>
        <div className='min-h-full flex flex-col items-center justify-center pb-20 lg:pb-0'>
          <div className='flex lg:hidden justify-center items-center h-full  bg-primary  w-full'>
            <div className='flex flex-col justify-between h-auto '>
              <div className='flex items-center justify-center'>
                <img src={logo} alt='Logo' className='block w-auto h-auto object-contain' />
              </div>
            </div>
          </div>
          <div>{children}</div>

          <div className='absolute w-[90%] lg:w-[300px] flex justify-between bottom-10'>
            {steps.map((step, index) => (
              <div
                key={index}
                className={`h-[8px] rounded-[4px] ${
                  index === currentStep ? 'bg-[#C41E3A]' : 'bg-gray-200'
                }`}
                style={{width: 'calc(100% / 5 - 4px)'}}
              ></div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default RegisterLayout;
