import {FC, lazy} from 'react';

import {Route, Routes} from 'react-router-dom';
import LoginPage from 'src/pages/account/LoginPage';
import RegisterPage from 'src/pages/account/RegisterPage';
const Content = lazy(() => import('./Content'));

const MainRouter: FC = () => {
  return (
    <Routes>
      <Route path='/*' element={<Content />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/register' element={<RegisterPage />} />
    </Routes>
  );
};

export default MainRouter;
