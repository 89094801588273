import {ErrorMessage} from '@hookform/error-message';
import React, {useImperativeHandle} from 'react';
import {get} from 'react-hook-form';

type RadioGroupProps = {
  name: string;
  label?: string;
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
} & React.InputHTMLAttributes<HTMLInputElement>;

const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  ({name, touched, errors, ...props}, ref) => {
    const inputRefTrue = React.useRef<HTMLInputElement>(null);
    const inputRefFalse = React.useRef<HTMLInputElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRefTrue.current!);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRefFalse.current!);

    const hasError = get(errors, name) && get(touched, name);

    return (
      <div>
        <div className='flex flex-row gap-4 '>
          <div className='bg-white'>
            <label className='text-base-regular flex items-center gap-x-2 border border-gray-300 p-2 rounded-lg'>
              <input {...props} type='radio' value='true' name={name} ref={inputRefTrue} /> Yes
            </label>
          </div>

          <div className='bg-white'>
            <label className='text-base-regular flex items-center gap-x-2 border border-gray-300 p-2 rounded-lg'>
              <input {...props} type='radio' value='false' name={name} ref={inputRefFalse} /> No
            </label>
          </div>
        </div>

        {hasError && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({message}) => {
              return (
                <div className='pt-1 pl-2 text-rose-500 text-xsmall-regular'>
                  <span>{message}</span>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  }
);

export default RadioGroup;
